import axios from "axios";
import React, { useEffect, useState} from "react";
import { useParams } from "react-router";
//import pdf from "../pdf/ChronostratChart2022-02.pdf"
import { Link } from "react-router-dom";
import { getCuencaFormacion, getFilo,getPartes, getGeneroEspecie, getPeriodoEpoca, postDatos, postEspecimen, postFilo, postGeneroEspecie, selectCuenca, selectEpoca, selectEspecie } from "../store/action";
import './prueba.css'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Alert, Ratio} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsFillFilePdfFill} from "react-icons/bs";
import { FiCheck,FiDelete} from "react-icons/fi";
import {gradoAdecimal,decimalAGrado} from '../store/action';
import Menu from "./menu";



import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

 
 
 
 
 export default function Prueba() {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const generoEspecie = useSelector((state)=> state.generoEspecie)
  const especiefiltrada = useSelector((state)=> state.especie)

  const filogenia = useSelector((state)=> state.filo )
  const partes = useSelector((state)=> state.partes )

  const periodoEpoca = useSelector((state)=> state.periodoepoca)
  const epocaFiltrada = useSelector((state)=> state.epoca)
  const [pisoFiltrado, setPisoFiltrado] = useState(null)



  const [imagenSelected, setImagenSelected] = useState(null)
  const [control, setControl] = useState(null)
  const [imagenes , setImagenes] = useState([])
  const [pdfs, setPdfs] = useState([])



  const [errors, setErrors] = useState({})
  const [ prueba, setPrueba] = useState(null)
  const [crear, setCrear] = useState({posicionfilo:[], partesesqueletales:[], publico:'no', holo:'no' })
  const [newId, setNewId] = useState(null);
  const [latitud, setLat] = useState(
      {
                  gra:0,
                  min:0,
                  seg:0,
                  coord:'S',
         
  })

  const [longitud, setLong] = useState(
      {
                   gra:0,
                  min:0,
                  seg:0,
                  coord:'W',
         
  })


 
  

  let generoEspecieFil = generoEspecie.map(el => el.genero)
  var checkHolo='';
  var checkPublico='';
  if(crear.holo===""||crear.holo==="no")checkHolo='true';
  if(crear.publico===""||crear.publico==="no")checkPublico='true';



  //---------------------use efect--------------------------//
  useEffect(() => {
      dispatch(getGeneroEspecie())
      dispatch(getCuencaFormacion())
          dispatch(getPeriodoEpoca())
          dispatch(getFilo())
          dispatch(getPartes())


           axios.get(`http://localhost:3001/especimen/?parametro=nuevo`)
          .then((response) => {
              setNewId(response.data)
             // console.log(response.data)
             
          }) 
          if (control){       
              setCrear({
                  ...crear,
                  imagen: control
              });
          }
          return () => {
            setNewId(null)  
          }
      }, [control&&newId===undefined]);

  //    console.log(crear)
//console.log(newId?.newId)
/////------------------------------FILTROS--------------------------//

   function filtrargenero(e){
 /*      if(e.target.value.length===0){
          setErrors({})
      } */
      let comprobar = generoEspecie.filter((el) => el.genero === e.target.value )

  if(comprobar.length === 0 && e.target.value.length>0){
      setErrors({
          genero: "¿Agregar genero?"
      })
     
  }else {
      setErrors({})
  dispatch(selectEspecie(e.target.value))
  
  e.preventDefault()
 
  setCrear({
      
      ...crear,
      [e.target.name] : e.target.value
  })
}
}






function filtrarEspecie(e){
  let comprobar1 = especiefiltrada.filter((el) => el === e.target.value )
  let generoInput = document.getElementById('genero-Input').value
  if(e.target.value.length === 0){
      setErrors({})
  }
  else if(comprobar1.length === 0 ){
      setErrors({
          especie: "Agregar especie para " + generoInput
      })
     
  }else {
      setErrors({})
  e.preventDefault()
  setCrear({
      
      ...crear,
      [e.target.name] : e.target.value
  })
}
}



function filtrarEpoca(e){
  dispatch(selectEpoca(e.target.value))
  e.preventDefault()
 
  setCrear({
      
      ...crear,
      [e.target.name] : e.target.value
  })
}

function filtrarPiso(e){
  let piso = epocaFiltrada.filter(el => el.nombre === e.target.value)
  setPisoFiltrado(piso[0].piso)
   e.preventDefault()
   setCrear({
       
       ...crear,
       [e.target.name] : e.target.value
   })
}

// ------------------- CREAR GENERO/ESPECIE------------------------------//

function crearGeneroEspecie(e){
  e.preventDefault()
  let generoInput = document.getElementById('genero-Input').value
//  console.log(generoInput)
  let modelo = 'genero'
      setErrors({})
      dispatch(postGeneroEspecie(modelo, generoInput))
      setTimeout(() => {dispatch(getGeneroEspecie())}, "1000")

}
function crearEspecie(e){

  e.preventDefault()
  let generoInput = document.getElementById('genero-Input').value
  let especieInput = document.getElementById('especie-Input').value
  let modelo = 'genero'
//  console.log(generoInput, especieInput, modelo)
  setErrors({})
  dispatch(postGeneroEspecie(modelo, generoInput, especieInput))
  setTimeout(() => {dispatch(getGeneroEspecie())}, "1000")

}

// ------------------------ posicion filogenetica ------------------------//
//................ELIMINA SELECCIONADO----
function handleDelete(el,e){
  e.preventDefault()

   setCrear({
      ...crear,
      posicionfilo: crear.posicionfilo.filter( occ => occ !== el)
  })
  
}
// ------------------------ partes  esqueletales ------------------------//
function  handleDeletePartes(el,e){
  e.preventDefault()

   setCrear({
      ...crear,
      partesesqueletales: crear.partesesqueletales.filter( occ => occ !== el)
  })
  
}

//------- AGREGA UNA NUEVA POSICION FILO---------------//
function handleSelectionFilo(e){


  setCrear({
    ...crear,
    posicionfilo: [...crear.posicionfilo, e.target.value]
  })

}
function handleSelectionPartes(e){


  setCrear({
    ...crear,
    partesesqueletales: [...crear.partesesqueletales, e.target.value]
  })

}
function modificarEspe (e) { // funcion para actualizar datos del estado(con las modificaciones)
  e.preventDefault()
  setCrear({ 
      ...crear,
      [e.target.name] : e.target.value
  })
}
const handleImg = (e) => {
  e.preventDefault()
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = async function () {
          //setControl([... control, reader.result])
          setImagenes([...imagenes,reader.result])
          setCrear({
              ...crear,
              imagen: [...imagenes, reader.result]
          })
      }
      if (file) {
          
          setImagenSelected(reader.readAsDataURL(file));
      }        

  }
  function eliminarImagen(el, e){
      e.preventDefault()
      let nuevo = imagenes.filter( occ => occ !== el)
      setImagenes(nuevo)
      setCrear({
          ...crear,
          imagen: nuevo
      })
      
  }
 
function submitEspecimen(e){ // funcion submit + modal de cartel 
  e.preventDefault()
 // console.log(latitud)
 
 var coorde=gradoAdecimal(latitud,longitud)

 console.log(coorde.latitud,coorde.longitud)

  // setCrear({
  //     ...crear,
  //     latitud: coorde.latitud,
  //     longitud: coorde.longitud,
  // })

  
 // console.log(crear)

 Swal.fire({
      title: 'Confirmas los cambios?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Cambio Exitoso', '', 'success')
       postEspecimen([crear,coorde]) // envio datos al back
       
                  setTimeout(() => {llamarIDnew()}, "2000")  // funcion para que solo cargue la pagina despues

      } else if (result.isDenied) {
        Swal.fire('No se realizaron cambios', '', 'info')
      }
    })
}
let filoNombre = filogenia.map(el => el.filo)
//console.log(partes);
function llamarIDnew(){
  axios.get(`http://localhost:3001/especimen/?parametro=nuevo`)
          .then((response) => {
              setNewId(response.data)})
}
function agregarFilo(e){
  e.preventDefault()
    
 
  const { value: formValues } =  Swal.fire({
      title: 'Ingrese Filogenia' ,
      html:
        
        '<input id="swal-input2" class="swal2-input">',
      focusConfirm: false,
      preConfirm: () => {
          var filo1
          document.getElementById('swal-input2').value.length===0?       Swal.fire(
              'Filogenia vacia',
              'Volver',
              'error'
            ): filo1 =  document.getElementById('swal-input2').value
        let filoNew = filo1[0].toUpperCase() + filo1.slice(1)
          let modelo = 'filo'
         
          
          Swal.fire(
              'Creado con Exito',
              'Volver',
              'success'
            )
        return [
            // console.log(gen, esp),
            filoNew.length===0?null:
            setCrear({
              ...crear,
              posicionfilo: [...crear.posicionfilo,filoNew]
            }),
        //  console.log(crear),
            setTimeout(() => {
              
              dispatch(postFilo(modelo, filoNew))
              dispatch(getFilo())
              }, "2000"), 
      
             /*  dispatch(getGeneroEspecie()),
              dispatch(selectEspecie(gen1), console.log('hice slect')),
              dispatch(postGeneroEspecie(modelo, gen1, espe1)), */
         
         
        
        ]
        
      }
      
    })
    
    if (formValues) {
      Swal.fire(JSON.stringify(formValues))
      
    }
}
function modificarPublico(e){
  console.log(e.target.value)
 
      setCrear({
          ...crear,
          publico: e.target.value,
      })  
 
  
}
function modificarHolotipo(e){
  //  console.log(e.target.checked)
  console.log(e.target.value)
 
  setCrear({
      ...crear,
      holo: e.target.value,
  })  

    
}

const subirArchivo = (e) => {
 
  const archivos = e.target.files;
  const data = new FormData();

  data.append('archivo', archivos[0]);

  axios.post('http://localhost:3001/subir-archivo', data)
  .then(data => {
    console.log(data)
   // document.getElementById('resultado').innerHTML = 'El archivo ' + data.data.filename + ' se ha subido correctamente.';
    setPdfs([...pdfs, data.data.filename])
    setCrear({
      ...crear,
      pdf: [...pdfs,data.data.filename]
    })
  })
  .catch(error => {
      console.error(error);
  });
  
}
function eliminarArchivo(e, el){
  e.preventDefault();
  axios.delete('http://localhost:3001/eliminar-archivo?nombreArchivo=' + el)
  let nuevo = pdfs.filter( occ => occ !== el)
  setPdfs(nuevo)
}

//console.log('longitud: ',longitud);
//console.log('latitud: ',latitud);


function modificarCoorLong(e){
 // console.log(e.target.selected)
 // console.log(e.target.value)
 // console.log(e.target.name)

  setLong({
      ...longitud,
      [e.target.name]:Number(e.target.value),

  })

    
}
function modificarCoorLat(e){
  //  console.log(e.target.checked)
 // console.log(e.target.value)
 // console.log(e.target.name)
 setLat({
      ...latitud,
      [e.target.name]:Number(e.target.value),

  })
    
}

 
  return(

  
    <div>
      <Menu/>
                                <datalist id='generoEspecie'>
                                    {
                                        generoEspecieFil.sort()?.map(el => {return <option>{el}</option>})
                                    }
                    
                                </datalist>
                                <datalist id='especiefiltrada'>
                                    {
                                        especiefiltrada?.sort()?.map(ele => {return <option>{ele}</option>})
                                        //especiefiltrada?.forEach(ele => {return <option>{ele}</option>})
                                       
                                    }
                                   
                                </datalist>                    
                                <datalist id='periodoEpoca'>
                                    {
                                        periodoEpoca?.map(elem => {return <option>{elem.periodo}</option>})
                                        //especiefiltrada?.forEach(ele => {return <option>{ele}</option>})
                                       
                                    }
                                     <datalist id='epocaFiltrada'>
                                    {
                                        epocaFiltrada?.map(eleme => {return <option>{eleme.nombre}</option>})
                                        //especiefiltrada?.forEach(ele => {return <option>{ele}</option>})
                                       
                                    }
                                   
                                </datalist>
                                <datalist id='pisoFiltrado'>
                                    {
                                       pisoFiltrado?.map(eleme => {return <option>{eleme}</option>})
                                        //especiefiltrada?.forEach(ele => {return <option>{ele}</option>})
                                       
                                    }
                                   
                                </datalist>
                                   
                                </datalist>
                                <datalist id='posiFilo'>
                                    {
                                        filogenia?.map(elemento => {return <option>{elemento.filo}</option>})
                                    }
                                </datalist>
      <div class='crear-espe'>
          <h2>CREAR ESPECIMEN NRO:{newId?.newId}</h2>
      </div>
      <div class='div-padre'>

          <div class='div-hijo1'> 
             <div class='flex'>
                  <div>
                    <label>Genero:</label>
                    <input type='text' id='genero-Input' class='css-input'name='genero' autoComplete='off' list='generoEspecie' onChange={(e)=> {filtrargenero(e)}}/>
                  </div>
                  <div>
                      <label>Especie:</label>
                      <input type='text' id = 'especie-Input'class='css-input'name='especie'autoComplete='off' list='especiefiltrada' onChange={(e)=> {filtrarEspecie(e)}}/>
                  </div>
              </div>    
              <div class='alert-div'>
                  <div class='alert-hijo'>
                        {
                          errors.genero?<span class="badge text-bg-success">
                                        {errors.genero} <button class='boton-alert' onClick={(e)=> crearGeneroEspecie(e)}><FiCheck/></button>
                                        </span>
                                        : <></>
                        }
                   </div>
                  <div class='alert-hijo'>
                        {
                          errors.especie?<span class="badge text-bg-success">
                                        {errors.especie} <button class='boton-alert' onClick={(e)=> crearEspecie(e)}><FiCheck/></button>
                                        </span>
                                        : <></>
                        }
                      </div>
                     </div>
                                      
                  <div class='filogenia'>
                    <div class='filogenia2'>
                        <label>Posicion Filogenetica:</label>
                        <p onClick={(e)=> agregarFilo(e)}>Nueva posicion</p>
                           <div class=''>
                              <div class='filogenia-select'>
                                 <select onChange={(e)=>handleSelectionFilo(e)}>
                                     {
                                         filoNombre.sort().map(ee => {return <option value={ee}>{ee}</option>})
                                     }
                                 </select> 
                              </div>
                            <div class='filogenia-agregada'>
                                  <ol class="list-group">
                                    {
                                    crear.posicionfilo?.length==0?<></>:crear.posicionfilo?.map(el => <li class="list-group-item d-flex justify-content-between align-items-start">
                                                                                                            <div class="ms-2 me-auto">
                                                                                                            <span class="badge text-bg-light">{el}</span>
                                                                                                              
                                                                                                            </div>
                                                                                                            <span class="badge bg-success rounded-pill"onClick={(e)=> handleDelete(el, e)}>X</span>
                                                                                                        </li>) 
                                    } 
                                  </ol>
                              </div>   
                            </div>
                                 
                   </div>  
                                    
                   <div class="partesEsqueleto" >
                                    <div class="tops"> 
                                        <label>Partes Esqueletales:</label>
                                    </div>
                                     <div class="conts">
                                      <div class='filo-todo2'>
                                            <div>
                                                <select multiple onChange={(e)=>handleSelectionPartes(e)}>
                                                    
                                                    {
                                                        partes.map(el => {return <option value={el}>{el}</option>})
                                                    }
                                                </select> 
                                           </div>
                                        </div>
                                
                                        
                                        <div>                  
                                            <ol class="list-group">
                                            {
                                            crear.partesesqueletales?.length==0?<></>:crear.partesesqueletales?.map(el => <li class="list-group-item d-flex justify-content-between align-items-start">
                                                                                                                            <div class="ms-2 me-auto">
                                                                                                                            <span class="badge text-bg-light">{el}</span>
                                                                                                                              
                                                                                                                            </div>
                                                                                                                            <span class="badge bg-success rounded-pill"onClick={(e)=> handleDeletePartes(el, e)}>X</span>
                                                                                                                              </li>) 
                                            } 
                                            </ol>
                                            
                                        </div>  
                                     </div>
                                        
                                
                                
                                </div>
                                */
                              </div>

          </div>
          <div class='div-hijo2'>

          </div>
          <div class='div-hijo3'>

          </div>
          <div class='div-hijo4'>

          </div>
      </div>
    </div> 
    
  )
 }