import axios from "axios";
import React, { useEffect, useState} from "react";
import { useParams } from "react-router";

import { Link } from "react-router-dom";
import dino from  './dinoGif.gif'
import { getCuencaFormacion, getFilo,getPartes, getGeneroEspecie, getPeriodoEpoca, postDatos, postEspecimen, postFilo,postCuencaFormacion, postGeneroEspecie, selectCuenca, selectEpoca, selectEspecie, postBochon,postParte } from "../store/action";
import './actualizarEspecimen.css'
import Swal from 'sweetalert2'
import crono from '../pdf/ChronostratChart2022-02SpanishAmer.pdf'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';

import {gradoAdecimal,decimalAGrado} from '../store/action';
import Menu from "./menu";
import {url} from '../URL.js'
import { BiPaste,BiArrowBack,BiArchiveIn } from "react-icons/bi";
import Resizer from "react-image-file-resizer";

// genero especie revisar , posicion filo mas de una
// comprobar datos en modal

export default function CrearEspecimen(){
    const dispatch = useDispatch();
    const navigate=useNavigate();
    const generoEspecie = useSelector((state)=> state.generoEspecie)
    const especiefiltrada = useSelector((state)=> state.especie)
    const [reservarInput, setReservarInput] = useState(0);
    const filogenia = useSelector((state)=> state.filo )
    const partes = useSelector((state)=> state.partes )
    const [todos, setTodos] = useState(null)
    const periodoEpoca = useSelector((state)=> state.periodoepoca)
    const epocaFiltrada = useSelector((state)=> state.epoca)
    const [pisoFiltrado, setPisoFiltrado] = useState(null)



    const [imagenSelected, setImagenSelected] = useState(null)
    const [control, setControl] = useState(null)
    const [imagenes , setImagenes] = useState([])
    const [pdfs, setPdfs] = useState([])

    const cuencaformacion = useSelector((state)=> state.cuencaformacion )
    const formacion = useSelector((state)=> state.formacion )


    const [errors, setErrors] = useState({genero:false,especie:false,cuenca:false,formacion:false})
    const [ prueba, setPrueba] = useState([''])
    const [crear, setCrear] = useState({posicionfilo:[], partesesqueletales:[], publico:'no', holo:'no' })
    const [newId, setNewId] = useState(null);
    const [numeroId, setNumeroId] = useState(null);
    const [sub, setSub] = useState(null);
    const [spinner, setSpinner] = useState(0);
    const [copiarInfo, setCopiarInfo] = useState(0);
    const  [ newIdBochon,setNewIdBochon] = useState(null)
    const [latitud, setLat] = useState(
        {
                    gra:0,
                    min:0,
                    seg:0,
                    coord:'S',
           
    })

    const [longitud, setLong] = useState(
        {
                     gra:0,
                    min:0,
                    seg:0,
                    coord:'W',
           
    })


   
    let que = useParams().que;
  //  console.log('prueba --->>> ', prueba)
  var descubridor=[];
  var miembro=[];
  var localidad=[];
  var campana=[];
  var preparador=[];
    var checkHolo='';
    var checkPublico='';
    if(crear.holo===""||crear.holo==="no")checkHolo='true';
    if(crear.publico===""||crear.publico==="no")checkPublico='true';

//console.log('latitud --->>>',latitud)
//console.log('latitud --->>>',longitud)

    //---------------------use efect--------------------------//
    useEffect(() => {
        dispatch(getGeneroEspecie())
        dispatch(getCuencaFormacion())
            dispatch(getPeriodoEpoca())
            dispatch(getFilo())
            dispatch(getPartes())
            axios.get(`${url}bochon/especimen/?parametro=nuevo`)
            .then((response) => {
                setNewIdBochon(response.data)
            })
             axios.get(`${url}especimen/?parametro=nuevoEsp`)
            .then((respons) => {
                setNewId(respons.data)

            }) 
          setCrear({
            posicionfilo:[], partesesqueletales:[], publico:'no', holo:'no'
          })
          setImagenes([])

          axios.get(`${url}especimen/`)
          .then((response2) => {
              setTodos(response2.data)
           })
        
            return () => {
              setNewId(null)  
            }
        }, [que]);






 /////------------------------------FILTROS--------------------------//

var llenado2=true;


 
 
        /////------------------------------FILTROS--------------------------//






//console.log('crear', crear)








if(llenado2){
    todos?.map(e=>{
        if(!descubridor.includes(e.descubridor)){
            descubridor.push(e.descubridor)
        }
        if(!miembro.includes(e.miembro)){
            miembro.push(e.miembro)
        }
        if(!localidad.includes(e.localidad)){
            localidad.push(e.localidad)
        }
        if(!campana.includes(e.campana)){
            campana.push(e.campana)
        }
        if(!preparador.includes(e.preparador)){
            preparador.push(e.preparador)
        }

    })

llenado2=false;

}

// console.log('descubridor',descubridor)
// console.log('miembro',miembro)
// console.log('localidad',localidad)
// console.log('campana',campana)
// console.log('preparador',preparador)





     function filtrargenero(e){
   /*      if(e.target.value.length===0){
            setErrors({})
        } */
        let comprobar = generoEspecie.filter((el) => el.genero === e.target.value )

    if(comprobar.length === 0 && e.target.value.length>0){
        setErrors({...errors,
            genero: "agregar genero",
        })
       
    }else {
        setErrors({...errors,genero:false})
    dispatch(selectEspecie(e.target.value))
    e.preventDefault()
    setCrear({
        ...crear,
        [e.target.name] : e.target.value
    })
}
}

var contImg=0;



function filtrarEspecie(e){
    let comprobar1 = especiefiltrada.filter((el) => el === e.target.value )
    let generoInput = document.getElementById('genero-Input').value
    if(e.target.value.length === 0){
        setErrors({...errors,especie:false})
    }
    else if(comprobar1.length === 0 ){
        setErrors({...errors,
            especie: "agregar especie"
        })
       
    }else {
        setErrors({...errors,especie:false})
    e.preventDefault()
    setCrear({
        
        ...crear,
        [e.target.name] : e.target.value
    })
}
}



function filtrarEpoca(e){
    dispatch(selectEpoca(e.target.value))
    e.preventDefault()
   
    setCrear({
        
        ...crear,
        [e.target.name] : e.target.value
    })
}

function filtrarPiso(e){
    let piso = epocaFiltrada.filter(el => el.nombre === e.target.value)
    setPisoFiltrado(piso[0].piso)
     e.preventDefault()
     setCrear({
         
         ...crear,
         [e.target.name] : e.target.value
     })
 }

// ------------------- CREAR GENERO/ESPECIE------------------------------//

function crearGeneroEspecie(e){
    e.preventDefault()
    let generoInput = document.getElementById('genero-Input').value
  //  console.log(generoInput)
    let modelo = 'genero'
        setErrors({...errors,genero:false})
        document.getElementById('especie-Input').value='';
        setCrear({
            ...crear,
            genero: generoInput
        })
        dispatch(postGeneroEspecie(modelo, generoInput))
        setTimeout(() => {dispatch(getGeneroEspecie())}, "1000")

}
function crearEspecie(e){

    e.preventDefault()
    let generoInput = document.getElementById('genero-Input').value
    let especieInput = document.getElementById('especie-Input').value
    let modelo = 'genero'
  //  console.log(generoInput, especieInput, modelo)
    setErrors({...errors,especie:false})
    setCrear({
        ...crear,
        especie: especieInput
    })
    dispatch(postGeneroEspecie(modelo, generoInput, especieInput))
    setTimeout(() => {dispatch(getGeneroEspecie())}, "1000")

}
function crearCuenca(e){
   e.preventDefault()
    let cuenca2 = document.getElementById('cuenca').value

    setCrear({
        ...crear,
        cuenca: cuenca2,
    })
    let modelo = 'cuenca'
        setErrors({...errors,cuenca:false})
       document.getElementById('formacion-in').value=''
    
        dispatch(postCuencaFormacion(modelo, cuenca2))
        setTimeout(() => {dispatch(getCuencaFormacion())}, "1000")

}

function crearFormacion(e){
    e.preventDefault()
  //console.log('entra crearFormacion')
    let cuenca2 = document.getElementById('cuenca').value
    let forma = document.getElementById('formacion-in').value
    setCrear({
        ...crear,
        formacion: forma,
    })

    let modelo = 'cuenca'
        setErrors({...errors,formacion:false})
        dispatch(postCuencaFormacion(modelo, cuenca2, forma))
        setTimeout(() => {dispatch(getCuencaFormacion())}, "1000")
      

}

// ------------------------ posicion filogenetica ------------------------//
//................ELIMINA SELECCIONADO----
function handleDelete(el,e){
    e.preventDefault()
    let cambio = prueba.filter( occ => occ !== el)
         setPrueba(
             cambio
         )

     setCrear({
        ...crear,
        posicionfilo: crear.posicionfilo.filter( occ => occ !== el)
    })
    
 }
 // ------------------------ partes  esqueletales ------------------------//
 function  handleDeletePartes(el,e){
    e.preventDefault()
    let cambio = prueba.filter( occ => occ !== el)
         setPrueba(
             cambio
         )

     setCrear({
        ...crear,
        partesesqueletales: crear.partesesqueletales.filter( occ => occ !== el)
    })
    
 }

 //------- AGREGA UNA NUEVA POSICION FILO---------------//
 
 
    
    
    function handleSelectionFilo(e){
        if(prueba.includes(e.target.value)){
          return(alert('filogenia ya seleccionada'))
        }else{

        setPrueba([...prueba, e.target.value])
        setCrear({
          ...crear,
          posicionfilo: [...crear.posicionfilo, e.target.value]
        })
    }
  
  
      
}
function handleSelectionPartes(e){
    if(prueba.includes(e.target.value)){
        return(alert('Parte ya selecionada...'))
      }else{

        setPrueba([...prueba, e.target.value])
    setCrear({
      ...crear,
      partesesqueletales: [...crear.partesesqueletales, e.target.value]
    })
}

}

function modificarEspe (e) { // funcion para actualizar datos del estado(con las modificaciones)
    e.preventDefault()
    setCrear({ 
        ...crear,
        [e.target.name] : e.target.value
    })
}
const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1200,
      1200,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
const handleImg = async (e) => {
    e.preventDefault()
    var file = e.target.files[0];
          
    const image = await resizeFile(file);
    
            setImagenes([...imagenes,image])
            setCrear({
                ...crear,
                imagen: [...imagenes, image]
            })
            if (file) {
                
                setImagenSelected(image);
            }        
        }
    
    
    function eliminarImagen(el,e){
       // e.preventDefault()
     
    //  console.log('eliminar imagen',e.target.src)  
      let nuevo = imagenes.filter( occ => occ !== el)
        setImagenes(nuevo)
       setCrear({
        ...crear,
        imagen: nuevo
    })
        
    }
  // console.log(crear)
function submitEspecimen(e){ // funcion submit + modal de cartel 
    e.preventDefault()
   if(que === 'especimen' && !crear.especimennumero){
    crear.especimennumero = newId.newId+'00';
    if(crear.bochonnumero === newIdBochon.newId){
        delete crear.bochonnumero
    }
   } else if( que === 'bochon' && !crear.bochonnumero){
    crear.bochonnumero = newIdBochon.newId
   delete  crear.especimennumero 
   }
   var coorde=gradoAdecimal(latitud,longitud)
   
 
   Swal.fire({
        title: 'Confirmas los cambios?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        customClass: {
          actions: 'my-actions',
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-2',
          denyButton: 'order-3',
        }
      }).then((result) => {
        if (result.isConfirmed) {
          
           
          //  console.log(crear) // envio datos al back
         if(que === 'especimen'){
            
            axios.post(`${url}especimen`, [crear,coorde])
              .then(res => {if(res.status == 202){
                Swal.fire('Creacion exitosa', '', 'success')
                 setTimeout(() => {window.location.reload()}, "1000")
              } else{ Swal.fire('Algo salio mal, vuelve a intentarlo ', '', 'warning')
              setTimeout(() => {window.location.reload()}, "2000")
                    }})
             
               // funcion para que solo cargue la pagina despues
          } else if( que === 'bochon'){
             axios.post(`${url}bochon/especimen`,[crear,coorde])
             .then(res => {if(res.status == 202){
                Swal.fire('Creacion exitosa', '', 'success')
                setTimeout(() => {window.location.reload()}, "1000")
             } else{ Swal.fire('Algo salio mal, vuelve a intentarlo ', '', 'warning')
             setTimeout(() => {window.location.reload()}, "2000")
                   }})
           
          } else {
            alert('Debes seleccionar que quieres crear (genero/bochon)')
          } 

        } else if (result.isDenied) {
          Swal.fire('No se realizaron cambios', '', 'info')
        }
      })
}
let filoNombre = filogenia.map(el => el.filo)
//console.log(partes);
/* function llamarIDnew(){
    axios.get(`${url}especimen/?parametro=nuevo`)
            .then((response) => {
                setNewId(response.data)})
} */
function agregarFilo(e){
    e.preventDefault()
      
   
    const { value: formValues } =  Swal.fire({
        title: 'Ingrese Filogenia' ,
        html:
          
          '<input id="swal-input2" class="swal2-input">',
        focusConfirm: false,
        preConfirm: () => {
            var filo1
            document.getElementById('swal-input2').value.length===0?       Swal.fire(
                'Filogenia vacia',
                'Volver',
                'error'
              ): filo1 =  document.getElementById('swal-input2').value
          let filoNew = filo1[0].toUpperCase() + filo1.slice(1)
            let modelo = 'filo'
           
            
            Swal.fire(
                'Creado con Exito',
                'Volver',
                'success'
              )
          return [
              // console.log(gen, esp),
              filoNew.length===0?null:
              setCrear({
                ...crear,
                posicionfilo: [...crear.posicionfilo,filoNew]
              }),
          //  console.log(crear),
              setTimeout(() => {
                
                dispatch(postFilo(modelo, filoNew))
                dispatch(getFilo())
                }, "2000"), 
        
               /*  dispatch(getGeneroEspecie()),
                dispatch(selectEspecie(gen1), console.log('hice slect')),
                dispatch(postGeneroEspecie(modelo, gen1, espe1)), */
           
           
          
          ]
          
        }
        
      })
      
      if (formValues) {
        Swal.fire(JSON.stringify(formValues))
        
      }
}
function agregarParte(e){
    e.preventDefault()


     const { value: formValues } =  Swal.fire({
        title: 'Ingrese Parte' ,
        html:

          '<input id="swal-input21" class="swal2-input">',
        focusConfirm: false,
        preConfirm: () => {
            var parte
            document.getElementById('swal-input21').value.length===0?       Swal.fire(
                'Campo vacio vuelve a intentarlo',
                'Volver',
                'error'
              ): parte =  document.getElementById('swal-input21').value
          let parteNew = parte[0].toUpperCase() + parte.slice(1)
        


            Swal.fire(
                'Creado con Exito',
                'Volver',
                'success'
              )
          return [
              setCrear({
                ...crear,
                partesesqueletales: [...crear.partesesqueletales,parteNew]
              }),
               setTimeout(() => {
                dispatch(postParte(parteNew))
                }, "1000"), 
          ]

        }

      })

      if (formValues) {
        Swal.fire(JSON.stringify(formValues))

      } 

}

   //console.log(crear)
function modificarPublico(e){
   // console.log(e.target.value)
   
        setCrear({
            ...crear,
            publico: e.target.value,
        })  
   
    
}
function modificarHolotipo(e){
    //  console.log(e.target.checked)
   /// console.log(e.target.value)
   
    setCrear({
        ...crear,
        holo: e.target.value,
    })  

      
  }
 // console.log('crear',crear)

  const subirArchivo = async (e) => {
   
    const archivos = await e.target.files;
    
    const data = new FormData();
  
    data.append('archivo', archivos[0]);
  setSpinner(1)
    axios.post(`${url}subir-archivo`, data)
    .then(data => {
        setSpinner(0)
     // console.log(data)
     // document.getElementById('resultado').innerHTML = 'El archivo ' + data.data.filename + ' se ha subido correctamente.';
      setPdfs([...pdfs, data.data.filename])
      setCrear({
        ...crear,
        pdf: [...pdfs,data.data.filename]
      })
    })
    .catch(error => {
       // console.error(error);
    });
    
}
function eliminarArchivo(e, el){
    e.preventDefault();
    axios.delete(`${url}eliminar-archivo?nombreArchivo=` + el)
    let nuevo = pdfs.filter( occ => occ !== el)
    setPdfs(nuevo)
}

  //console.log('longitud: ',longitud);
  //console.log('latitud: ',latitud);

  
  function modificarCoorLong(e){
   // console.log(e.target.selected)
   // console.log(e.target.value)
   // console.log(e.target.name)
  if(e.target.name==='coord'){
    setLong({
        ...longitud,
        [e.target.name]:e.target.value,
    
    })

  }else{ 
    if(e.target.name === 'gra'){
        var co = document.getElementById('input4').value
        
        let co2 = document.getElementById('input5')
        if(co.length == 2){
            co2.focus()
        }
    } else if(e.target.name === 'min'){
        let co = document.getElementById('input5').value
        let co2 = document.getElementById('input6')
        if(co.length == 2){
            co2.focus()
        }
    }
    setLong({
    ...longitud,
    [e.target.name]:Number(e.target.value),

})
}
   
  
      
  }
 // console.log('latitud',latitud)
 // console.log('longitud',longitud)
  function modificarCoorLat(e){
    //  console.log(e.target.checked)
   // console.log(e.target.value)
  //  console.log(e.target.name)
  if(e.target.name==='coord'){
    setLat({
        ...latitud,
        [e.target.name]:e.target.value,

    })
      

  }else{ 
    if(e.target.name === 'gra'){
        let co = document.getElementById('input1').value
        let co2 = document.getElementById('input2')
        if(co.length == 2){
            co2.focus()
        }
    } else if(e.target.name === 'min'){
        let co = document.getElementById('input2').value
        let co2 = document.getElementById('input3')
        if(co.length == 2){
            co2.focus()
        }
    }
   
    setLat({
        ...latitud,
        [e.target.name]:Number(e.target.value),

    })
      
}


      
  }

  function capitalizarPrimeraLetra(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function handleCuenca(e){

 e.preventDefault()

    let comprobar = cuencaformacion.filter((el) => el.cuenca === e.target.value)

    if(comprobar.length === 0 && e.target.value.length>0){
        setErrors({...errors,
            cuenca: "agregar cuenca"
        })
       
    }else {
        setErrors({...errors, cuenca:false})
        setTimeout(() => {dispatch(selectCuenca(e.target.value))}, "1000")
     
          
          
           setCrear({
               
               ...crear,
               cuenca : e.target.value
           })
      
}
 //console.log('crear',crear)
  }

  function handelForma(e){
 e.preventDefault()
   //   console.log( e.target.value )
  
      let comprobar = formacion.filter((el) => el === e.target.value )
  
      if(comprobar.length === 0 && e.target.value.length>0){
          setErrors({...errors,
              formacion: "agregar formación"
          })
         
      }else {
          setErrors({...errors,formacion:false})
            e.preventDefault()
            
             setCrear({
                 
                 ...crear,
                 formacion: e.target.value
             })
        
  }
  
    }


function cambiarNumeroEspecimen(e){
   
    
    if(que === 'especimen'){
       
        //console.log(newId.numero[0])
        var caca=newId.numero[0].filter(el=>el.especimennumero.slice(0,el.especimennumero.length-2)==e.target.value)
       //console.log(caca)
       let sub2='';
        if(caca.length>0){
            if(caca.length<10){
                setSub('0'+caca.length)
                sub2='0'+caca.length;
            }else{
                setSub(caca.length.toString())
                sub2='0'+caca.length
              
            }
        }else{
            setSub('00')
            sub2='00'
        }

//console.log('sub >>>',sub)
setCrear({
    ...crear,
    especimennumero:e.target.value+sub2,
    bochonnumero:'',
}) 
       

    } else if (que === 'bochon') {
        
        setCrear({
            ...crear,
            bochonnumero: e.target.value,
        }) 
    } 
}
function SortArray(x, y){
    if (Number(x) < Number(y)) {return -1;}
      if (Number(x) > Number(y)) {return 1;}
      return 0;
    }
//console.log(newId?.numeros)
var numerosSort = newId?.numeros.sort(SortArray)
//console.log(numerosSort)
function botonCopiar () {
    setCopiarInfo(1)
}



function copiar (){
if(que === 'especimen'){
    var id = document.getElementById('traer-especimen').value
    id = id + '00'
    

    if(!newId.faltantes.includes(id)){
       alert('No existe el número de especimen seleccionado.')
    } else{
       
     
      
     axios.get(`${url}especimen/id?id=${Number(id)}`)
    .then(res => {
        document.getElementById('periodo-input').value = res.data.periodo
        document.getElementById('epoca-input').value = res.data.epoca
        document.getElementById('piso-input').value = res.data.piso
        document.getElementById('cuenca').value = res.data.cuenca
        document.getElementById('formacion-in').value = res.data.formacion
        document.getElementById('miembro-input').value = res.data.miembro
        document.getElementById('localidad-input').value = res.data.localidad
        document.getElementById('campana-input').value = res.data.campana
        document.getElementById('fechaC-input').value = res.data.fechadescubrimiento
        setCrear({
               
            ...crear,
            periodo: res.data.periodo,
            epoca:  res.data.epoca,
            piso: res.data.piso,
            cuenca: res.data.cuenca,
            formacion: res.data.formacion,
            miembro:res.data.miembro,
            localidad: res.data.localidad,
            campana: res.data.campana,
            fechadescubrimiento:res.data.fechadescubrimiento,
            posicionfilo: res.data.posicionfilo,

        })
        setCopiarInfo(0)
    }) 
}

}else if(que == 'bochon'){
    var id = document.getElementById('traer-especimen').value
    
    if(newIdBochon.faltantes.includes(Number(id))||newIdBochon.newId==id){
        alert('No existe el número de bochón seleccionado.')
    } else{
    
    axios.get(`${url}bochon/especimen/id?id=${id}`)
    .then(res => {
        document.getElementById('periodo-input').value = res.data.periodo
        document.getElementById('epoca-input').value = res.data.epoca
        document.getElementById('piso-input').value = res.data.piso
        document.getElementById('cuenca').value = res.data.cuenca
        document.getElementById('formacion-in').value = res.data.formacion
        document.getElementById('miembro-input').value = res.data.miembro
        document.getElementById('localidad-input').value = res.data.localidad
        document.getElementById('campana-input').value = res.data.campana
        document.getElementById('fechaC-input').value = res.data.fechadescubrimiento
        setCrear({
               
            ...crear,
            periodo: res.data.periodo,
            epoca:  res.data.epoca,
            piso: res.data.piso,
            cuenca: res.data.cuenca,
            formacion: res.data.formacion,
            miembro:res.data.miembro,
            localidad: res.data.localidad,
            campana: res.data.campana,
            fechadescubrimiento:res.data.fechadescubrimiento,
            posicionfilo: res.data.posicionfilo,

        })
        setCopiarInfo(0)
    })
    } 
}
}
function stateReservar() {
    setReservarInput(1)
}
function  reservar(e){
    let input = document.getElementById('reservar-especimen')?.value
    
    if(input.length > 0) {
        if(window.confirm('¿ Seguro que quieres reservar ' + input+ ' ?')){
            if(que == 'especimen') {
                axios.post(`${url}reserva/` + input)
                .then(res => alert(res.data), setReservarInput(0))
            } else if(que == 'bochon'){
                axios.post(`${url}bochon/reserva/` + input)
                .then(res => alert(res.data),setReservarInput(0))
            }
        } else {
            alert('No se realizaron reservas')
        }

    } else {
        alert('no ingresó ningun número')
    }
}
function cancelar(){
    
    setCopiarInfo(0)
}
function cancelarR(){
    
    setReservarInput()
}
function SortArray(x, y, param){

    if (x.genero < y.genero) {return -1;}
    if (x.genero > y.genero) {return 1;}
    return 0;

}

function SortArray2(x, y, param){

if (x.periodo < y.periodo) {return -1;}
if (x.periodo > y.periodo) {return 1;}
return 0;


}
let generoSort=generoEspecie.sort(SortArray);
let periodoSort=periodoEpoca.sort(SortArray2);
return (   
    crear?
    <div className="container34">
                
    <datalist id='generoEspecie'>
        {
            generoSort?.map(el => {return <option>{el.genero}</option>})
        }

    </datalist>
    <datalist id='formacionCuenca'>
        {
            cuencaformacion?.map(el => {return <option>{el.cuenca}</option>})
        }

    </datalist>
    <datalist id='formacion'>
        {
           formacion?.map(el => {return <option>{el}</option>})
        }

    </datalist>
    <datalist id='especiefiltrada'>
        {
            especiefiltrada?.map(ele => {return <option>{ele}</option>})
            //especiefiltrada?.forEach(ele => {return <option>{ele}</option>})
           
        }
       
    </datalist>

    <datalist id='periodoEpoca'>
        {
            periodoSort?.map(elem => {return <option>{elem.periodo}</option>})
            //especiefiltrada?.forEach(ele => {return <option>{ele}</option>})
           
        }
         <datalist id='epocaFiltrada'>
        {
            epocaFiltrada?.map(eleme => {return <option>{eleme.nombre}</option>})
            //especiefiltrada?.forEach(ele => {return <option>{ele}</option>})
           
        }
       
    </datalist>
    <datalist id='pisoFiltrado'>
        {
           pisoFiltrado?.map(eleme => {return <option>{eleme}</option>})
            //especiefiltrada?.forEach(ele => {return <option>{ele}</option>})
           
        }
       
    </datalist>
       
    </datalist>
    <datalist id='posiFilo'>
        {
            filogenia?.map(elemento => {return <option>{elemento.filo}</option>})
        }
    </datalist>
    <datalist id='descubridor'>
                    {
                    descubridor?.sort().map(el => {return <option>{el}</option>})
                    }

                </datalist>
                <datalist id='miembro'>
                    {
                    miembro?.sort().map(el => {return <option>{el}</option>})
                    }

                </datalist>
                <datalist id='localidad'>
                    {
                    localidad?.sort().map(el => {return <option>{el}</option>})
                    }

                </datalist>
                <datalist id='campana'>
                    {
                    campana?.sort().map(el => {return <option>{el}</option>})
                    }

                </datalist>
                <datalist id='preparador'>
                    {
                    preparador?.sort().map(el => {return <option>{el}</option>})
                    }

                </datalist>
   
    <div>
        {que==='especimen'?<Menu activo={2}/>:<Menu activo={3}/>}
     
    </div>
   
    <div className="contenido34">
        <div className="cabecera">
            

                {que==='especimen'?
                <> <div className="vuelve">
                <Link to={'/home/'} className="limpiar">VOLVER</Link>
                </div>
      <div className="a44">
      CREANDO ESPÉCIMEN 
      <select onChange={(e) => cambiarNumeroEspecimen(e)} class='select-numeroespecimen'>
        <option value={newId?.newId}>{newId?.newId}</option>
        {
            numerosSort?.map(el => {return <option value={el}>{el}</option>})
        }
      </select>
      <p> SUB: {sub?sub:'00'} </p>
      </div>
      {
        copiarInfo == 1 ? 
        <> 
        <input placeholder='nro esp..' id='traer-especimen'></input>
        <button onClick={(e)=> copiar()} class='boton-copiar-1'>✔</button>
        <button onClick={(e)=> cancelar()} class='boton-copiar-1'>❌</button>
        </>
        :<button onClick={(e)=> botonCopiar()} class='boton-copiar'><span tooltip='Copiar Especimen'><BiPaste fontSize='1.4rem'/></span></button>
      }
         </>                   
                                 
             
                :
                <> <div className="vuelve">
                <Link to={'/home/bochon'} className="limpiar">VOLVER</Link>
                </div>
      <div className="a">
        CREANDO BOCHÓN 
      <select onChange={(e) => cambiarNumeroEspecimen(e)} class='select-numeroespecimen'>
        <option value={newIdBochon?.newId}>{newIdBochon?.newId}</option>
        {
            newIdBochon?.faltantes.map(el => {return <option value={el}>{el}</option>})
        }
      </select>
      </div>
      {
        copiarInfo == 1 ? 
        <> 
        <input placeholder='nro esp..' id='traer-especimen'></input>
        <button onClick={(e)=> copiar()} class='boton-copiar-1'>✔</button>
        <button onClick={(e)=> cancelar()} class='boton-copiar-1'>❌</button>
        </>
        :
        <button onClick={(e)=> botonCopiar()} class='boton-copiar'><span tooltip='Copiar Bochón'><BiPaste fontSize='1.4rem'/></span></button>
      }
      </>
              

    }
        {
            reservarInput === 1 ? <><input placeholder='cantidad...' id='reservar-especimen'></input>
            <button onClick={(e)=> reservar(e)} >✔</button><button onClick={(e)=> cancelarR(e)} >❌</button></>
            :
            <button className="boton-copiar" onClick={(e)=> stateReservar(e)}><span tooltip='Reservar'><BiArchiveIn fontSize='1.4rem'></BiArchiveIn></span></button>
          }   
              
        </div>
        <div className="cuerpo">
            <div className="col1">
          <div className="info1">
                                    <div className=" texto">
                                        <label className="label">Género:</label>
                                        </div>
                                    <div className="content">

                                    <input type='text' id='genero-Input' className="in" name='genero' autoComplete='off' list='generoEspecie' onChange={(e)=> {filtrargenero(e)}}/>
                                    {
                                        errors.genero?<p  className="p2" onClick={(e)=> crearGeneroEspecie(e)}>+ agregar género</p>
                                        : <></>
                                    }

                                </div>
                               

                            </div>
             
               
                            <div className="info1">
                                 <div className=" texto">
                                   <label className="label">Especie:</label>
                                 </div>
                                 <div className="content">
                                    <input type='text' id = 'especie-Input'class='in'name='especie'autoComplete='off' list='especiefiltrada' onChange={(e)=> {filtrarEspecie(e)}}/>
                                    {
                                      errors.especie?<p  className="p2" onClick={(e)=> crearEspecie(e)}>+ agregar especie</p>
                                        :  <></>
                                    }
                                 </div>




                            </div>
                            <div className="info1">
                              <div className=" texto">
                                <label className="label">Nro. Bochón:</label>
                            </div>
                            <div className="content">
                                {que==='especimen'?<input type='number'  min={0} class='in' name='bochonnumero' onChange={(e)=> {modificarEspe(e)}} />
                                :<input disabled='true' type='number'  min={0} class='in' name='especimennnumero' onChange={(e)=> {modificarEspe(e)}} />}
                                
                            </div>
                              
                                </div>

                                <div  class='info1'>
                <div className="texto">
                 <label className="label">Descubridor:</label>
                </div>
          <div className="content">
          <input type='text' class='in' list='descubridor'name='descubridor'onChange={(e)=> {modificarEspe(e)}} />
          </div>

     

            </div>
                <div className="info3">
                 

                     <div className="mostrador">
                        <div className="cabeza">
                        <label className="lab">Pos. Filo:</label>
                                    
                                    <select  onChange={(e)=>handleSelectionFilo(e)} >
                                    <option>Agregar...</option>
                                        {
                                                filoNombre.sort().map(ee => {return <option value={ee}>{ee}</option>})
                                            }
                                    </select> 
                        </div>
                    
                         {
                         crear.posicionfilo.length===0?<div className="mostradores">Sin Posición Filogenética...</div>:crear.posicionfilo.length<9?crear.posicionfilo?.map(el => {return <div className="caca32" onClick={(e)=> handleDelete(el, e)}><span tooltip="click para eliminar" >{el} </span></div>}):
                         crear.posicionfilo?.map(el => {return <div className="caca" onClick={(e)=> handleDelete(el, e)}><span tooltip="click para eliminar" >{el} </span></div>})
                         } 
                        
                         </div>
                         <div className="base1" onClick={(e)=> agregarFilo(e)} >
                         <h6 className="h67"> + nueva posición</h6>
                     </div>
                </div>
                
                <div className="info3">
                                 
                            
                    <div className="mostrador2">   
                    <div className="cabeza">        
                    <label className="lab">Partes:</label>
                     <select onChange={(e)=>handleSelectionPartes(e)}>
                     <option>Agregar...</option>
                                        {
                                            partes.map(el => {return <option value={el}>{el}</option>})
                                        }
                                    </select> 
                                    </div>
                                {
                                crear?.partesesqueletales?.length===0?<div className="mostradores">Sin partes...</div>:crear?.partesesqueletales?.length<9?crear?.partesesqueletales?.map(el => {return <div className="caca32" onClick={(e)=> handleDeletePartes(el, e)}><span tooltip="click para eliminar" >{el} </span></div> }):
                                crear?.partesesqueletales?.map(el => {return <div className="caca" onClick={(e)=> handleDeletePartes(el, e)}><span tooltip="click para eliminar" >{el} </span></div> })  
                                } 
                            </div>
                                 <div className="base1" onClick={(e)=> agregarParte(e)} >
                                                <h6 className="h67"> + agregar parte </h6>
                                            </div>
                </div>

                
            </div> 
            <div className="col1">
                <div className="info1">
                   <div className="texto">
                   <label className="label"><a className="labelH" href={crono} target='_blank'>Periodo: </a></label>
                   </div>
                   <div className="content">
                   <input type='text' name='periodo' id='periodo-input' className="in"  autoComplete='off' list='periodoEpoca' onChange={(e)=> {filtrarEpoca(e)}} />
                   </div>
                  
                 
                
                </div>
                <div  class='info1'>
                <div className="texto">
                    <label className="label">Época:</label>
                    </div>
                    <div className="content">
                    <input type='text'  id='epoca-input' className="in" name='epoca'autoComplete='off' list='epocaFiltrada'onChange={(e)=> {filtrarPiso(e)} } />
                    </div>
                </div>
                <div  class='info1'>
                <div className="texto">
                    <label className="label">Piso:</label>
                    </div>
                    <div className="content">
                    <input type='text' id='piso-input' className="in" name='piso'autoComplete='off' list='pisoFiltrado'onChange={(e)=> {modificarEspe(e)}} />
                    </div>
                </div>
                <div  class='info1'>
                <div className="texto">
                    <label className="label">Cuenca:</label>
                    </div>
                    <div className="content">
                    <input type='text' name='cuenca' id='cuenca' className="in" list='formacionCuenca' autoComplete='off' onChange={(e)=> {handleCuenca(e)}} />
                    {
                                        errors.cuenca?<p  className="p2" onClick={(e)=> crearCuenca(e)}>+ agregar cuenca</p>
                                        : <></>
                                    }

                    </div>
                </div>
                <div  class='info1'>
                <div className="texto">
                    <label className="label">Formación:</label>
                    </div>
                    <div className="content">
                     <input type='text'  name='formacion' id='formacion-in' className="in" list='formacion'autoComplete='off'onChange={(e)=> {handelForma(e)}} />
                     {
                                        errors.formacion?<p  className="p2" onClick={(e)=> crearFormacion(e)}>+ agregar fomacion</p>
                                        : <></>
                                    }
                     </div>
                </div>
                
                <div  class='info1'>
                <div className="texto">
                    <label className="label">Miembro:</label>
                    </div>
                    <div className="content">
                    <input type='text' name='miembro' list='miembro' id='miembro-input'className="in" onChange={(e)=> {modificarEspe(e)}} />
                    </div>
                </div>
                <div  class='info1'>
                <div className="texto">
                    <label className="label">Localidad:</label>
                    </div>
                    <div className="content">
                    <input type='text' className="in" list='localidad'id='localidad-input' name='localidad'onChange={(e)=> {modificarEspe(e)}} />
                    </div>
                 </div>
                 <div  class='info1'>
                 <div className="texto">
                 <label className="label">Campaña:</label>
                 </div>
                 <div className="content">
     <input type='text' className="in" list='campana'id='campana-input'name='campana'onChange={(e)=> {modificarEspe(e)}} />
     </div>
                 </div>
                 
                 <div  class='info1'>
                 <div className="texto">
                 <label className="label">Fecha Camp.:</label>
                 </div>
                 <div className="content">
     <input type='date' className="in" id='fechaC-input'name='fechadescubrimiento'onChange={(e)=> {modificarEspe(e)}}/>
     </div>
                 </div>
        
          
            </div>
            <div className="col1">
              <div  class='info1'>
                <div className="texto">
                <label className="label">Nro Campo:</label>
                </div>
          <div className="content">
          <input type='number' class='in'name='nrocampo'onChange={(e)=> {modificarEspe(e)}}/>
          </div>
             
                
                    
            </div>
            <div  class='info141'>
                       <div className="texto2">
                       <label  className="label23">Coordenadas Geográficas </label>
                       </div>
                            <div className="coorde">
                                    <div className="coordeIn">
                                            
                                        <select name='coord' className="coorden" onChange={(e)=>{modificarCoorLat(e)}}>
                                            <option value='S'>S</option>
                                            <option value='N'>N</option>
                                        </select><p className="p"></p>

                                    <input className="coor" min={0} type='number' id='input1' name='gra'  onChange={(e)=>{modificarCoorLat(e)}} ></input><p className="p">{'°'}</p>
                                    <input className="coor" min={0} type='number' id='input2' name='min'  onChange={(e)=>{modificarCoorLat(e)}} ></input> <p className="p">{"'"} </p>
                                    <input  className="coorseg"  min={0} type='number' id='input3' name='seg'  onChange={(e)=>{modificarCoorLat(e)}} ></input> <p className="p">{'" '}</p>
                                </div>
                                </div>


                            <div className="coorde">
                                <div className="coordeIn">
                            
                            <select name='coord' className="coorden" onChange={(e)=>{modificarCoorLong(e)}}>
                                <option value='W'>W</option>
                                <option value='E'>E</option>
                            </select>
                             <p className="p"> </p>
                    <input type='number'  name='gra' id='input4'  class='coor' min={0} onChange={(e)=>{modificarCoorLong(e)}}></input><p className="p">{'°'}</p>
                    <input type='number' name='min'  class='coor' id='input5'  min={0} onChange={(e)=>{modificarCoorLong(e)}}></input><p className="p">{"'"}</p>
                    <input type='number' name='seg' class='coorseg' id='input6' min={0} onChange={(e)=>{modificarCoorLong(e)}}></input><p className="p">{'"'}</p>

                            </div>
                            </div>
                        </div>

            
            <div  class='info1'>
                <div className="texto">
                 <label className="label">Preparador:</label>
                </div>
          <div className="content">
          <input type='text' class='in'name='preparador'onChange={(e)=> {modificarEspe(e)}} />
          </div>

     

            </div>
       
            <div  class='info1'>
            <div className="texto">
            <label className="label">Fecha Prep.:</label>
            </div>
            <div className="content">
            <input type='date' class='in'name='preparacionfecha'onChange={(e)=> {modificarEspe(e)}} />
            </div>
           
 
            </div >
            <div  class='info1'>
            <div className="texto">
            <label className="label">Fragmentos:</label>
            </div>
            <div className="content">
            <input type='number'  min={0} class='in' name='cantidadfrag' onChange={(e)=> {modificarEspe(e)}} />
            </div>
        
            </div>
            <div  class='info1coment'>
                        <div className="texto">
                              <label className="label">Comentario:</label>
                            </div>
                           

                            <div className="content">
                         <textarea type='text' class='textar'name='comentario'onChange={(e)=> {modificarEspe(e)}} />
                      </div>
                      </div>
                      <div  class='info1'>
                             <div className="texto">
                             <label className="label">Público:</label>
                             </div>
                                         <div className="inpus2">
                                         <div>
                                        {'SI  '}
                                         {crear?.publico ==='si'?<input type='radio' name='publico' value="si" checked='true'  onClick={(e)=> {modificarPublico(e)}} />:<input type='radio' name='publico' value="si"  onClick={(e)=> {modificarPublico(e)}} />}
                                         </div>
                                         <div>
                                         {'NO  '}
                                         {crear?.publico==='no'?<input type='radio' name='publico' value='no' checked='true' onClick={(e)=> {modificarPublico(e)}}/>:<input type='radio' name='publico' value='no'   onClick={(e)=> {modificarPublico(e)}}/>    }
                                         </div>
                                         </div>



                             </div> 
                      <div  class='info1'>
                             <div className="texto">
                             <label className="label">Holotipo:</label>
                             </div>
                                         <div className="inpus2">
                                         <div>
                                        {'SI  '}
                                         {crear?.holo ==='si'?<input type='radio' name='holotipo' value="si" checked='true'  onClick={(e)=> {modificarHolotipo(e)}} />:<input type='radio' name='holotipo' value="si"  onClick={(e)=> {modificarHolotipo(e)}} />}
                                         </div>
                                         <div>
                                         {'NO  '}
                                         {crear?.holo === 'no'?<input type='radio' name='holotipo' value='no' checked='true' onClick={(e)=> {modificarHolotipo(e)}}/>:<input type='radio' name='holotipo' value='no'   onClick={(e)=> {modificarHolotipo(e)}}/>    }
                                         </div>
                                         </div>



                             </div> 

            </div>
            <div className="col1">
            <div className="info3B1">

<div className="cabeza5">
    <label className="lab">Armario:</label>

    <input type='number' min={0}  name='armario1'onChange={(e)=> {modificarEspe(e)}}/>


</div>
<div className="estante">
    <label class='labelo'>Estante </label>
    <input className="ubic" min={0} type='number' name='estante1desde'onChange={(e)=> {modificarEspe(e)}} />
    <label class='labelo'>Hasta</label>
    <input className="ubic" min={0} type='number'  name='estante1hasta'onChange={(e)=> {modificarEspe(e)}} />

</div>


</div>

<div className="info3B1">

<div className="cabeza5">
<label className="lab">Armario:</label>

<input type='number' min={0} name='armario2'onChange={(e)=> {modificarEspe(e)}} />


</div>
<div className="estante">
<label class='labelo'>Estante </label>
<input className="ubic"  type='number' min={0} name='estante2desde'onChange={(e)=> {modificarEspe(e)}} />
<label class='labelo'>Hasta</label>
<input className="ubic" type='number' min={0} name='estante2hasta'onChange={(e)=> {modificarEspe(e)}} />

</div>


</div>
               
               
                <div className="info3">
                    <div className="mostrador3">   
                        <div className="cabeza3">        
                            <label className="lab">Publicaciones</label>
                            <input onChange={(e) => subirArchivo(e)} type="file" id="pdf" name="pdf" accept="application/pdf" className="buto"/>
                        </div>
                            {
                            pdfs?.length>0?pdfs.map(el => {
                                return <div className="caca2" onClick={(e)=> eliminarArchivo(e, el)}><span tooltip="click para eliminar" className="butono">{el} </span></div>
                                    }): spinner === 1 ? <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                         </Spinner>
                                                         
                                 
                                    :
                                    <>Sin publicaciones cargadas...</>
                            }
                           
                    </div>
                </div>
                <div className="info3">
                                <div className="mostrador3">
                                    <div className="cabeza3">
                                        <label className="lab">Imágenes</label>
                                        <input onChange={(e) => handleImg(e)} type="file" id="imagen" name="imagen" accept="image/png, image/jpeg" className="buto"/>

                                    </div>
                                    {

                                            crear?.imagen?.length>0?crear.imagen.map(el => {
                                                return <div>
                                                    <span tooltip="click para eliminar" className="butono"><img src={el} height="60px" width="80px" alt="image" onClick={(e)=> eliminarImagen(el, e)}></img></span>
                                                   
                                                </div>


                                            }):  <>Sin imágenes cargadas</>
                                    }
                                </div>
                            </div>
            
 
        
        </div>
        </div>{
            errors?.genero||errors?.especie||errors?.cuenca||errors?.formacion?<div className="pieErr">
                <p className="cargar23" >{'FORMULARIO INCOMPLETO'}</p>
        
          {errors.genero?<p className="cargar2"  onClick={(e)=> crearGeneroEspecie(e)} >{errors.genero}</p>:''}
          {errors.especie?<p className="cargar2" onClick={(e)=> crearEspecie(e)}>{errors.especie}</p>:''}
          {errors.cuenca?<p className="cargar2" onClick={(e)=> crearCuenca(e)}>{errors.cuenca}</p>:''}
          {errors.formacion?<p className="cargar2" onClick={(e)=> crearFormacion(e)} >{errors.formacion}</p>:''}
          
          
</div>:<div className="pie">
                        <p className="cargar" onClick={(e)=>submitEspecimen(e)}>CARGAR</p>
        </div>
        }
        
    </div>
</div> //termina container


:<>{dino}</>)
}