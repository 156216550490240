import axios from "axios";
import React, { useEffect, useState} from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import crono from '../pdf/ChronostratChart2022-02SpanishAmer.pdf'
import { gradoAdecimal,decimalAGrado,fechaActual,getPartes, getCuencaFormacion,getDatos, getFilo,selectCuenca, getGeneroEspecie, getPeriodoEpoca, postDatos,postCuencaFormacion, postFilo, postGeneroEspecie, selectEpoca, selectEspecie, postBochon, putBochon, getBochones, postEspecimen,postParte, subespecimen } from "../store/action";
import {url} from '../URL.js'
import './actualizarEspecimen.css'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import tablinum from "../componentes/TABLINUM.jpg"
import { useDispatch, useSelector } from "react-redux";
import {Alert} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { FiCheck,FiDelete} from "react-icons/fi";
import { Carousel } from 'react-bootstrap';
import Menu from "./menu";
import Resizer from "react-image-file-resizer";
import Spinner from 'react-bootstrap/Spinner';





export default function ActualizarEspecimen (){
    const dispatch = useDispatch();
    const [especimen, setEspecimen] = useState(null)
    const [ prueba, setPrueba] = useState(null)
    const [todos, setTodos] = useState(null)

    const generoEspecie = useSelector((state)=> state.generoEspecie)
    const especiefiltrada = useSelector((state)=> state.especie)

    const filogenia = useSelector((state)=> state.filo )
    const partes = useSelector((state)=> state.partes )

    const periodoEpoca = useSelector((state)=> state.periodoepoca)
    const epocaFiltrada = useSelector((state)=> state.epoca)
    const [pisoFiltrado, setPisoFiltrado] = useState(null)

    const [coordenadas, setCordenadas] = useState()

    const cuencaFormacion = useSelector((state)=> state.cuencaformacion )
    const formacion = useSelector((state)=> state.formacion )
    const [spinner, setSpinner] = useState(0);


    const [imagenSelected, setImagenSelected] = useState(null)

    const [control, setControl] = useState([])

   // const [upDate, setUpDate] = useState(0)
    const [imagenes , setImagenes] = useState([])

    const [pdfs, setPdfs] = useState([])
    const [modificacion, setModificacion] = useState(
        {
            usuario:'Santiago',
            fecha:'',
            espPrev:{},
            espNew:{},
        })

        const [errors, setErrors] = useState({genero:false,especie:false,cuenca:false,formacion:false})

    let id = useParams()
    let ids = id.id
    const navigate=useNavigate();
   // const [crear, setCrear] = useState({holo:'', publico:'' })
    const [modificar, setModificar] = useState({especimennumero:ids})

   //useeffect

    useEffect(() => {
        dispatch(getGeneroEspecie())
        dispatch(getCuencaFormacion())
            dispatch(getPeriodoEpoca())
            dispatch(getFilo())
            dispatch(getPartes())
            axios.get(`${url}especimen/id?id=${ids}`)
            .then((response) => {
                setEspecimen(response.data)
                setPrueba(response.data.posicionfilo)
                setImagenes(response.data.imagen)
                setPdfs(response.data.pdf)
                setCordenadas(decimalAGrado(response.data.coordlat,response.data.coordlong))


            })
            axios.get(`${url}especimen`)
            .then((response2) => {
                setTodos(response2.data)
             


            })
            return () => {
                setEspecimen(null)
            }
        }, [control])


//GENERO LA FECHA ACTUAL PARA
var fecha=fechaActual();


//LLENADO DE EL ESTADO MODIFICACION(ESPECIMEN PREV Y NEW)
if(especimen){
  //  console.log('hay')

if(!modificacion.espPrev.especimennumero){
   // especimen.modificado=[];
        setModificacion({
            ...modificacion,
            fecha:fecha,
            espPrev:especimen,
        })

}else if(especimen!==modificacion.espNew){
    setModificacion({
        ...modificacion,
        espNew:especimen,
    })

}
}

//OBTEBCION DE COORDENADAS DESDE ESPECIMEN (TRUE OR FALSE DE LOS DELECT DE COORDENADAS)

//var coordenadas =  decimalAGrado(especimen?.coordlat,especimen?.coordlong)


//console.log(coordenadas)
// LLENADO PARA LOS DATALIST
var llenado=true;
var descubridor=[];
var miembro=[];
var localidad=[];
var campana=[];
var preparador=[];

if(llenado){
    todos?.map(e=>{
        if(!descubridor.includes(e.descubridor)){
            descubridor.push(e.descubridor)
        }
        if(!miembro.includes(e.miembro)){
            miembro.push(e.miembro)
        }
        if(!localidad.includes(e.localidad)){
            localidad.push(e.localidad)
        }
        if(!campana.includes(e.campana)){
            campana.push(e.campana)
        }
        if(!preparador.includes(e.preparador)){
            preparador.push(e.preparador)
        }

    })

llenado=false;

}
console.log('descubridor',descubridor)
console.log('miembro',miembro)
console.log('localidad',localidad)
console.log('campana',campana)
console.log('preparador',preparador)





    function modificarEspe (e) { // funcion para actualizar datos del estado(con las modificaciones)
        e.preventDefault()
        setEspecimen({
            ...especimen,
            [e.target.name] : e.target.value,
        })

    }


    function filtrargenero(e){
        let comprobar = generoEspecie.filter((el) => el.genero === e.target.value )

        if(comprobar.length === 0 && e.target.value.length>0){
            setErrors({...errors,
                genero: "agregar genero",
            })
           
        }else {
            setErrors({...errors,genero:false})
        dispatch(selectEspecie(e.target.value))
        
        e.preventDefault()
       
        setEspecimen({

            ...especimen,
            [e.target.name] : e.target.value,
            especie:'',
        })
        document.getElementById('especie-Input').placeholder='';
    }
    }

    function filtrarEspecie(e){

        let comprobar1 = especiefiltrada.filter((el) => el === e.target.value )
        let genero1 = document.getElementById('genero-Input').value
        let genero2 = document.getElementById('genero-Input').placeholder
         let generoInput
            if(genero1.length>1){
                generoInput = genero1
            }else{
                generoInput = genero2
            }
            if(e.target.value.length === 0){
                setErrors({...errors,especie:false})
            }
            else if(comprobar1.length === 0 ){
                setErrors({...errors,
                    especie: "agregar especie"
                })
               
            }else {
                setErrors({...errors,especie:false})
            e.preventDefault()
        setEspecimen({

            ...especimen,
            [e.target.name] : e.target.value
        })
    }
    }

    function filtrarEpoca(e){
        dispatch(selectEpoca(e.target.value))
        e.preventDefault()

        setEspecimen({

            ...especimen,
            [e.target.name] : e.target.value,
            epoca:'',
            piso:'',
        })
        document.getElementById('epoca-input').placeholder='';
        document.getElementById('piso-input').placeholder='';
        document.getElementById('epoca-input').value='';
        document.getElementById('piso-input').value='';
    }

    function filtrarPiso(e){
       let piso = epocaFiltrada.filter(el => el.nombre === e.target.value)
       setPisoFiltrado(piso[0].piso)
        e.preventDefault()
        setEspecimen({

            ...especimen,
            [e.target.name] : e.target.value,
            piso:'',
        })
        document.getElementById('piso-input').placeholder='';

        document.getElementById('piso-input').value='';
    }
    
    function crearCuenca(e){
        e.preventDefault()
         let cuenca2 = document.getElementById('cuenca').value
     
         setEspecimen({
             ...especimen,
             cuenca: cuenca2,
         })
         let modelo = 'cuenca'
         setErrors({...errors,cuenca:false})
             dispatch(postCuencaFormacion(modelo, cuenca2))
             setTimeout(() => {dispatch(getCuencaFormacion())}, "1000")
     
     }
     
     function crearFormacion(e){
         e.preventDefault()
       //console.log('entra crearFormacion')
         let cuenca2 = document.getElementById('cuenca').value
         let forma = document.getElementById('formacion-in').value
         setEspecimen({
             ...especimen,
             formacion: forma,
         })

         
    let modelo = 'cuenca'
    setErrors({...errors,formacion:false})
    dispatch(postCuencaFormacion(modelo, cuenca2, forma))
    setTimeout(() => {dispatch(getCuencaFormacion())}, "1000")
     
        }


    function crearEspecie(e){
        e.preventDefault()
        let especieInput = document.getElementById('especie-Input').value
        var genero1 = document.getElementById('genero-Input').value
        var genero2 = document.getElementById('genero-Input').placeholder
        let generoInput
            if(genero1.length>1){
                generoInput = genero1
            }else{
                generoInput = genero2
            }
        let modelo = 'genero'
       // console.log(generoInput, especieInput, modelo)
       setErrors({...errors,especie:false})
        setEspecimen({
            ...especimen,
            especie: especieInput
        })
        dispatch(postGeneroEspecie(modelo, generoInput, especieInput))
        setTimeout(() => {dispatch(getGeneroEspecie())}, "1000")
    }

    function crearGeneroEspecie(e){
        e.preventDefault()
        e.preventDefault()
        let generoInput = document.getElementById('genero-Input').value
       // console.log(generoInput)
        let modelo = 'genero'
        setErrors({...errors,genero:false})
            setEspecimen({
                ...especimen,
                genero: generoInput
            })
            dispatch(postGeneroEspecie(modelo, generoInput))
            setTimeout(() => {dispatch(getGeneroEspecie())}, "1000")

    }

    function handleDelete(el,e){
       e.preventDefault()
       let cambio = prueba.filter( occ => occ !== el)
            setPrueba(
                cambio
            )
        setEspecimen({
            ...especimen,
            posicionfilo: cambio
        })

    }

    function handleSelectionFilo(e){
        if(prueba.includes(e.target.value)){
          return(alert('filogenia ya seleccionada'))
        }else{

        setPrueba([...prueba, e.target.value])
        setEspecimen({
          ...especimen,
          posicionfilo: [...prueba, e.target.value]
        })
    }
    }

   // console.log('especimen : ',especimen)
    //console.log(modificacion)


    function agregarFilo(e){
        e.preventDefault()


        const { value: formValues } =  Swal.fire({
            title: 'Ingrese Filogenia' ,
            html:

              '<input id="swal-input2" class="swal2-input">',
            focusConfirm: false,
            preConfirm: () => {
                var filo1
                document.getElementById('swal-input2').value.length===0?       Swal.fire(
                    'Filogenia vacia',
                    'Volver',
                    'error'
                  ): filo1 =  document.getElementById('swal-input2').value
              let filoNew = filo1[0].toUpperCase() + filo1.slice(1)
                let modelo = 'filo'


                Swal.fire(
                    'Creado con Exito',
                    'Volver',
                    'success'
                  )
              return [
                  // console.log(gen, esp),
                  filoNew.length===0?null:
                  setPrueba([...prueba, filoNew]),
                 
                   setEspecimen({
                    ...especimen,
                    posicionfilo: [...prueba, filoNew]
                  }), 

                  setTimeout(() => {

                    dispatch(postFilo(modelo, filoNew))
                    dispatch(getFilo())
                    }, "2000"),



              ]

            }

          })

          if (formValues) {
            Swal.fire(JSON.stringify(formValues))

          }
    }

    function agregarParte(e){
        e.preventDefault()


         const { value: formValues } =  Swal.fire({
            title: 'Ingrese Nueva Parte ' ,
            html:

              '<input id="swal-input21" class="swal2-input">',
            focusConfirm: false,
            preConfirm: () => {
                var parte
                document.getElementById('swal-input21').value.length===0?       Swal.fire(
                    'Campo vacio vuelve a intentarlo',
                    'Volver',
                    'error'
                  ): parte =  document.getElementById('swal-input21').value
              let parteNew = parte[0].toUpperCase() + parte.slice(1)
                


                Swal.fire(
                    'Creado con Exito',
                    'Volver',
                    'success'
                  )
              return [
                  setEspecimen({
                    ...especimen,
                    partesesqueletales: [...especimen.partesesqueletales,parteNew]
                  }),
                   setTimeout(() => {
                    dispatch(postParte(parteNew))
                    }, "1000"),
                    /* setUpDate(upDate +1) */
              ]

            }

          })

          if (formValues) {
            Swal.fire(JSON.stringify(formValues))

          } 

    }
//console.log('espec --->>> ',especimen)
const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1200,
      1200,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "Base64"
    );
  });
const handleImg = async(e) => {

        e.preventDefault()
            var file = e.target.files[0];
            console.log('archivo-->', file.size)           
            const image = await resizeFile(file);
            console.log('modificado-->',image.size)
                 setImagenes([...imagenes,image])
                setEspecimen({
                    ...especimen,
                    imagen: [...imagenes, image]
                })            
            if (file) {
                setImagenSelected(image);
            } 

        }


    function eliminarImagen(el, e){
        e.preventDefault()
        let nuevo = especimen.imagen.filter( occ => occ !== el)
        setEspecimen({
            ...especimen,
            imagen : nuevo,
        })
        setModificar({
            ...modificar,
            imagen: nuevo
        })

    }

    let filoNombre = filogenia.map(el => el.filo);

    function modificarPublico(e){
        //console.log(e.target.value)

            setEspecimen({
                ...especimen,
                publico: e.target.value,
            })


    }
    function modificarHolotipo(e){
        //  console.log(e.target.checked)
        //console.log(e.target.value)

        setEspecimen({
            ...especimen,
            holotipo: e.target.value,
        })



      }



      function handleSelectionPartes(e){
        e.preventDefault()
       // console.log(e.target.value)
if(!especimen.partesesqueletales.includes(e.target.value)){
     setEspecimen({
          ...especimen,
          partesesqueletales: [...especimen.partesesqueletales,e.target.value],
        })
    } else {
        alert( 'Parte esqueletal '+e.target.value+' ya seleccionada')
    }

    }

    function  handleDeletePartes(el,e){
        e.preventDefault()

         setEspecimen({
            ...especimen,
            partesesqueletales: especimen.partesesqueletales.filter( occ => occ !== el)
        })


     }





//COORDENADAS

//console.log('coordenadas:   ',coordenadas)
  function  handleLat(e){
       // e.preventDefault()

       if(e.target.name==='grados'){
        coordenadas.latitud.gra=Number(e.target.value);
        let co = document.getElementById('input1').value
        let co2 = document.getElementById('input2')
        if(co.length == 2){
            co2.focus()
        }
       }
       if(e.target.name==='minutos'){
        coordenadas.latitud.min=Number(e.target.value);
        let co = document.getElementById('input2').value
        let co2 = document.getElementById('input3')
        if(co.length == 2){
            co2.focus()
        }
       }
       if(e.target.name==='segundos'){
        coordenadas.latitud.seg=Number(e.target.value);
       }
       if(e.target.name==='coord'){
        coordenadas.latitud.coord=e.target.value;
       }

    //    console.log('lat:   ',coordenadas.latitud)
       var decimal=gradoAdecimal(coordenadas.latitud,coordenadas.longitud);
    //   console.log('decimal:   ',decimal)
         setEspecimen({
            ...especimen,
            coordlat: decimal.latitud
        })


     }

     function  handleLong(e){
     //   e.preventDefault()
       if(e.target.name==='grados'){
        coordenadas.longitud.gra=Number(e.target.value);
        let co = document.getElementById('input4').value
        let co2 = document.getElementById('input5')
        if(co.length == 2){
            co2.focus()
        }
       }
       if(e.target.name==='minutos'){
        coordenadas.longitud.min=Number(e.target.value);
        let co = document.getElementById('input5').value
        let co2 = document.getElementById('input6')
        if(co.length == 2){
            co2.focus()
        }
       }
       if(e.target.name==='segundos'){
        coordenadas.longitud.seg=Number(e.target.value);
       }
       if(e.target.name==='coord'){
        coordenadas.longitud.coord=e.target.value;
       }

        console.log('long:   ',coordenadas.longitud)

        var decimal=gradoAdecimal(coordenadas.latitud,coordenadas.longitud);
       // console.log('decimal:   ',decimal)
        setEspecimen({
            ...especimen,
            coordlong: decimal.longitud,
        })


     }


     
     function handleCuenca(e){

        e.preventDefault()
       
           let comprobar = cuencaFormacion.filter((el) => el.cuenca === e.target.value)
       
           if(comprobar.length === 0 && e.target.value.length>0){
            setErrors({...errors,
                cuenca: "agregar cuenca"
            })
           
        }else {
            setErrors({...errors, cuenca:false})
            setTimeout(() => {dispatch(selectCuenca(e.target.value))}, "1000")
                 
                  setEspecimen({
                      
                      ...especimen,
                      cuenca : e.target.value
                  })
             
       }
  
         }
       
         function handelForma(e){
        e.preventDefault()
             console.log( e.target.value )
         
             let comprobar = formacion.filter((el) => el === e.target.value )
         
             if(comprobar.length === 0 && e.target.value.length>0){
                setErrors({...errors,
                    formacion: "agregar formación"
                })
               
            }else {
                setErrors({...errors,formacion:false})
                  e.preventDefault()
                   
                   setEspecimen({
                        
                        ...especimen,
                        formacion: e.target.value
                    })
               
         }
         
           }
       
       

  //   console.log('coordenadas --->>> ',coordenadas)

//////////////////////////////////////////////////////////////
////////////////  SUBMIT    //////////////////////////////////
//////////////////////////////////////////////////////////////
function submitEspecimen(e){ // funcion submit + modal de cartel

    e.preventDefault()


  //  console.log('especimen submit', especimen)

    Swal.fire({
        title: 'Confirma los cambios?',
        showDenyButton: true,
        confirmButtonText: 'Si',
        
        denyButtonText: 'No',
        customClass: {
            actions: 'my-actions',
            cancelButton: 'order-1 right-gap',
            confirmButton: 'order-2',
            denyButton: 'order-3',
        }
    }).then((result) => {
        if (result.isConfirmed) {

         

      //    console.log(modificar)
             postDatos([especimen,modificacion]) // envio datos al back
                setEspecimen(null) // seteo estado local a null

                    setTimeout(() => {navigate(`/home/${ids}`)}, "1000")   // funcion para que solo cargue la pagina despues
                    setTimeout(() => {getDatos()}, "1000")                                               // que los cambio se guarden en back

        } else if (result.isDenied) {
          Swal.fire('No se realizaron cambios', '', 'info')
        }
      })
}


const subirArchivo = (e) => {

    const archivos = e.target.files;
    const data = new FormData();

    data.append('archivo', archivos[0]);
    setSpinner(1)
    axios.post(`${url}subir-archivo`, data)
    .then(data => {
    //  console.log(data)
    setSpinner(0)
      setPdfs([...pdfs, data.data.filename])
      setEspecimen({
        ...especimen,
        pdf: [...pdfs,data.data.filename]
      })
    })
    .catch(error => {

        document.getElementById('resultado').innerHTML = 'Error al cargar PDF!';
    });


}
function eliminarArchivo(e, el){
    e.preventDefault();
    axios.delete(`${url}eliminar-archivo?nombreArchivo=` + el)
    let nuevo = pdfs.filter( occ => occ !== el)
    setPdfs(nuevo)
    setEspecimen({
        ...especimen,
        pdf: nuevo
    })
}
function SortArray(x, y, param){

    if (x.genero < y.genero) {return -1;}
    if (x.genero > y.genero) {return 1;}
    return 0;

}

function SortArray2(x, y, param){

if (x.periodo < y.periodo) {return -1;}
if (x.periodo > y.periodo) {return 1;}
return 0;


}
let generoSort=generoEspecie.sort(SortArray);
let periodoSort=periodoEpoca.sort(SortArray2);
   
    return (

            <div className="container34">

                <datalist id='generoEspecie'>
                    {
                        generoSort?.map(el => {return <option>{el.genero}</option>})
                    }

                </datalist>
                <datalist id='especiefiltrada'>
                    {
                        especiefiltrada?.map(ele => {return <option>{ele}</option>})
                        //especiefiltrada?.forEach(ele => {return <option>{ele}</option>})

                    }

                </datalist>
                <datalist id='formacionCuenca'>
        {
            cuencaFormacion?.map(el => {return <option>{el.cuenca}</option>})
        }

    </datalist>
    <datalist id='formacion'>
        {
           formacion?.map(el => {return <option>{el}</option>})
        }

    </datalist>

                <datalist id='periodoEpoca'>
                    {
                        periodoSort?.map(elem => {return <option>{elem.periodo}</option>})
                        //especiefiltrada?.forEach(ele => {return <option>{ele}</option>})

                    }
                     <datalist id='epocaFiltrada'>
                    {
                        epocaFiltrada?.map(eleme => {return <option>{eleme.nombre}</option>})
                        //especiefiltrada?.forEach(ele => {return <option>{ele}</option>})

                    }

                </datalist>
                <datalist id='pisoFiltrado'>
                    {
                       pisoFiltrado?.map(eleme => {return <option>{eleme}</option>})
                        //especiefiltrada?.forEach(ele => {return <option>{ele}</option>})

                    }

                </datalist>

                </datalist>
                <datalist id='posiFilo'>
                    {
                        filogenia?.map(elemento => {return <option>{elemento.filo}</option>})
                    }
                </datalist>
                <datalist id='descubridor'>
                    {
                    descubridor?.sort().map(el => {return <option>{el}</option>})
                    }

                </datalist>
                <datalist id='miembro'>
                    {
                    miembro?.sort().map(el => {return <option>{el}</option>})
                    }

                </datalist>
                <datalist id='localidad'>
                    {
                    localidad?.sort().map(el => {return <option>{el}</option>})
                    }

                </datalist>
                <datalist id='campana'>
                    {
                    campana?.sort().map(el => {return <option>{el}</option>})
                    }

                </datalist>
                <datalist id='preparador'>
                    {
                    preparador?.sort().map(el => {return <option>{el}</option>})
                    }

                </datalist>
               

                  
                    
                <Menu activo={0}/>
                    <div className="contenido34">
                   
                        <div className="cabecera">

                        <div className="vuelve">
                <Link to={'/home/'+especimen?.especimennumero} className="limpiar">VOLVER</Link>
                </div>
              
                    <div className="a">
                    Modificando especimen {especimen?subespecimen(especimen?.especimennumero):''}
                    </div>
                                  
                                  
                                  

                       
                        </div>
                        <div className="cuerpo">

                            <div className="col1">
                                <div className="info1">
                                    <div className=" texto">
                                        <label className="label">Género:</label>
                                        </div>
                                    <div className="content">

                                    <input type='text' id='genero-Input' className="in" name='genero' autoComplete='off' list='generoEspecie' onChange={(e)=> {filtrargenero(e)}} placeholder={modificacion?.espPrev.genero}/>
                                    {
                                        errors.genero?<p  className="p2" onClick={(e)=> crearGeneroEspecie(e)}>+ agregar género</p>
                                        : <></>
                                    }

                                </div>
                               

                            </div>
                           



                            <div className="info1">
                                 <div className=" texto">
                                   <label className="label">Especie:</label>
                                 </div>
                                 <div className="content">
                                    <input type='text' id = 'especie-Input'class='in'name='especie'autoComplete='off' list='especiefiltrada' onChange={(e)=> {filtrarEspecie(e)}} placeholder={modificacion?.espPrev.especie}/>
                                    {
                                      errors.especie?<p  className="p2" onClick={(e)=> crearEspecie(e)}>+ agregar especie</p>
                                        :  <></>
                                    }
                                 </div>




                            </div>
                            <div className="info1">
                              <div className=" texto">
                                <label className="label">Nro. Bochón:</label>
                            </div>
                            <div className="content">
                                
                                <input type='number'  class='in' name='bochonnumero' onChange={(e)=> {modificarEspe(e)}} defaultValue={modificacion?.espPrev.bochonnumero}/>
                            </div>
                              
                                </div>
                                <div  class='info1'>
                        <div className="texto">
                             <label className="label">Descubridor:</label>
                        </div>
                       <div className="content">
                             <input type='text' className="in" name='descubridor' list='descubridor' onChange={(e)=> {modificarEspe(e)}} placeholder={modificacion?.espPrev.descubridor}/>
                        </div>
                     </div>
                                <div className="info3">

                                 <div className="mostrador">
                                    <div className="cabeza">
                                    <label className="lab">Pos. Filo:</label>

                                                <select  onChange={(e)=>handleSelectionFilo(e)}>
                                                <option>Seleccione...</option>
                                                    {
                                                            filoNombre.sort().map(ee => {return <option value={ee}>{ee}</option>})
                                                        }
                                                </select>
                                    </div>

                                     {
                                     prueba?.length===0?<> Sin Posicion Filogenetica</>: prueba?.length<9?prueba?.map(el => {return <div className="caca32" onClick={(e)=> handleDelete(el, e)}><span tooltip="click para eliminar" >{el} </span></div>}):
                                     prueba?.map(el => {return <div className="caca" onClick={(e)=> handleDelete(el, e)}><span tooltip="click para eliminar" >{el} </span></div>})
                                     }
                                     </div>
                                 <div className="base1" onClick={(e)=> agregarFilo(e)} >
                                     <h6 className="h67"> + nueva posición</h6>
                                 </div>
                            </div>

                            <div className="info3">


                                <div className="mostrador2">
                                <div className="cabeza">
                                <label className="lab">Partes:</label>
                                 <select onChange={(e)=>handleSelectionPartes(e)}>
                                 <option id="partesOp">Seleccione...</option>
                                                    {
                                                        partes.map(el => {return <option value={el}>{el}</option>})
                                                    }
                                                </select>
                                                </div>
                                            {
                                            especimen?.partesesqueletales?.length===0?<> Sin partes cargadas </>:especimen?.partesesqueletales?.length<9?especimen?.partesesqueletales?.map(el => {return <div className="caca32" onClick={(e)=> handleDeletePartes(el, e)}><span tooltip="click para eliminar" >{el} </span></div> }):
                                            especimen?.partesesqueletales?.map(el => {return <div className="caca" onClick={(e)=> handleDeletePartes(el, e)}><span tooltip="click para eliminar" >{el} </span></div> })
                                            }
                                        </div>
                                             <div className="base1" onClick={(e)=> agregarParte(e)} >
                                                <h6 className="h67"> + agregar parte </h6>
                                            </div>
                            </div>


                        </div>
                        <div className="col1">
                            <div className="info1">
                            <div className="texto">
                                <label className="label"> <span tooltip="TABLA CRONOESTRATIGRÁFICA" ><a className="labelH" href={crono} target='_blank'>Periodo:</a></span></label>
                                </div>
                                <div className="content">
                                    <input type='text' name='periodo' className="in" autoComplete='off' list='periodoEpoca' onChange={(e)=> {filtrarEpoca(e)}} placeholder={modificacion?.espPrev.periodo}/>
                                 </div>
                            </div>
                            <div  class='info1'>
                            <div className="texto">
                                <label className="label">Época:</label>
                                </div>
                                <div className="content">

                                <input type='text'  id='epoca-input' className="in" name='epoca'autoComplete='off' list='epocaFiltrada'onChange={(e)=> {filtrarPiso(e)} } placeholder={modificacion?.espPrev.epoca}/>
                                </div>

                            </div>
                            <div  class='info1'>
                            <div className="texto">
                                <label className="label">Piso:</label>

                                </div>
                                <div className="content">
                                <input type='text' id='piso-input'name='piso'autoComplete='off' className="in" list='pisoFiltrado'onChange={(e)=> {modificarEspe(e)}} placeholder={modificacion?.espPrev.piso}/>
                                            </div>
                            </div>
                            <div  class='info1'>
                            <div className="texto">
                                <label className="label">Cuenca:</label>
                                </div>
                                <div className="content">
                                <input type='text' name='cuenca' id='cuenca'list='formacionCuenca' className="in" autoComplete='off' onChange={(e)=> {handleCuenca(e)}} placeholder={modificacion?.espPrev.cuenca}/>
                                {
                                        errors.cuenca?<p  className="p2" onClick={(e)=> crearCuenca(e)}>+ agregar cuenca</p>
                                        : <></>
                                    }
                                    </div>
                            </div>
                            <div  class='info1'>
                            <div className="texto">
                                <label className="label">Formación:</label>
                                </div>
                                <div className="content">
                                 <input type='text'  name='formacion' id='formacion-in' className="in"  list='formacion'autoComplete='off'onChange={(e)=> {handelForma(e)}} placeholder={modificacion?.espPrev.formacion}/>
                                 {
                                        errors.formacion?<p  className="p2" onClick={(e)=> crearFormacion(e)}>+ agregar fomacion</p>
                                        : <></>
                                    }
                                 </div>
                            </div>

                            <div  class='info1'>
                            <div className="texto">
                                <label className="label">Miembro:</label>
                                </div>
                                <div className="content">
                                <input type='text' name='miembro' list='miembro' className="in" onChange={(e)=> {modificarEspe(e)}} placeholder={modificacion?.espPrev.miembro}/>
                                </div>
                            </div>
                            <div  class='info1'>
                            <div className="texto">
                                <label className="label">Localidad:</label>
                                </div>
                                <div className="content">
                                <input type='text' className="in" list='localidad' name='localidad'onChange={(e)=> {modificarEspe(e)}} placeholder={modificacion?.espPrev.localidad}/>
                                </div>
                             </div>
                             <div  class='info1'>
                                <div className="texto">
                                     <label className="label">Campaña:</label>
                                </div>
                               <div className="content">
                                     <input type='text' className="in" list='campana' name='campana'onChange={(e)=> {modificarEspe(e)}} placeholder={modificacion?.espPrev.campana}/>
                                </div>
                             </div>
                             <div  class='info1'>
                                <div className="texto">
                                  <label className="label">Fecha Camp.:</label>
                                </div>
                                <div className="content">
                                      <input type='date' className="in"name='fechadescubrimiento'onChange={(e)=> {modificarEspe(e)}} defaultValue={modificacion?.espPrev.fechadescubrimiento}/>
                                 </div>
                             </div>
                     
                             
                        </div>
                        <div className="col1">
                        <div  class='info1'>
                            <div className="texto">
                            <label className="label">Nro Campo:</label>
                            </div>
                        <div className="content">
                        <input type='number' class='in'name='nrocampo'onChange={(e)=> {modificarEspe(e)}} placeholder={modificacion?.espPrev.nrocampo}/>
                        </div>



               </div>
               <div  class='info141'>
                       <div className="texto2">
                       <label  className="label23">Coordenadas Geográficas </label>
                       </div>

                                
                           

                            <div className="coorde">
                                    <div className="coordeIn">
                                            
                                    <select name='coord' className="coorden" onChange={(e)=>{handleLat(e)}}>
                                    {coordenadas?.latitud.coord==='N'?<option value='N' selected='true'>N</option>:<option value='N'>N</option>}
                                    {coordenadas?.latitud.coord==='S'?<option value='S' selected='true'>S</option>:<option value='S'>S</option>}
                                    </select><p className="p"></p>

                                    <input className="coor" min='0' type='number' id='input1' name='grados' placeholder={coordenadas?.latitud.gra} onChange={(e)=>{handleLat(e)}} ></input><p className="p">{'°'}</p>
                                    <input className="coor" min='0' type='number'id='input2' name='minutos' placeholder={coordenadas?.latitud.min} onChange={(e)=>{handleLat(e)}} ></input> <p className="p">{"'"} </p>
                                    <input  className="coorseg" min='0' type='number'id='input3' name='segundos' placeholder={coordenadas?.latitud.seg} onChange={(e)=>{handleLat(e)}} ></input> <p className="p">{'" '}</p>
                                </div>
                                </div>


                            <div className="coorde">
                                <div className="coordeIn">
                            
                            <select name='coord' className="coorden" onChange={(e)=>{handleLong(e)}}>
                                {coordenadas?.longitud.coord==='E'?<option value='E' selected='true'>E</option>:<option value='E'>E</option>}
                                {coordenadas?.longitud.coord==='W'?<option value='W' selected='true'>W</option>:<option value='W'>W</option>}
                            </select>
                             <p className="p"> </p>
                    <input type='number' min='0' name='grados'id='input4' placeholder={coordenadas?.longitud.gra} class='coor'  onChange={(e)=>{handleLong(e)}}></input><p className="p">{'°'}</p>
                    <input type='number' min='0' name='minutos'id='input5' placeholder={coordenadas?.longitud.min} class='coor'  onChange={(e)=>{handleLong(e)}}></input><p className="p">{"'"}</p>
                    <input type='number' name='segundos' min='0'id='input6' placeholder={coordenadas?.longitud.seg} class='coorseg'  onChange={(e)=>{handleLong(e)}}></input><p className="p">{'"'}</p>

                            </div>
                            </div>
                        </div>


                        <div  class='info1'>
                        <div className="texto">
                        <label className="label">Preparador:</label>
                        </div>
                        <div className="content">
                        <input type='text' class='in'name='preparador' list='preparador' onChange={(e)=> {modificarEspe(e)}} placeholder={modificacion?.espPrev.preparador}/>
                        </div>


                        </div>
                        <div  class='info1'>
                                <div className="texto">
                                <label className="label">Fecha Prep.:</label>
                                </div>
                                <div className="content">
                                <input type='date' class='in' name='preparacionfecha'onChange={(e)=> {modificarEspe(e)}} defaultValue={modificacion?.espPrev.preparacionfecha}/>
                            </div>
                        </div >

                        
                       
                        <div  class='info1coment'>
                        <div className="texto">
                              <label className="label">Comentario:</label>
                            </div>
                           

                            <div className="content">
                         <textarea type='text' class='textar'name='comentario'onChange={(e)=> {modificarEspe(e)}} defaultValue={modificacion?.espPrev.comentario}/>
                      </div>
                      </div>

                        <div  class='info1'>
                        <div className="texto">
                        <label className="label">Fragmentos:</label>
                        </div>
                            <div className="content">
                    <input type='number'  class='in' name='cantidadfrag' onChange={(e)=> {modificarEspe(e)}} defaultValue={modificacion?.espPrev.cantidadfrag}/>
                    </div>
                        </div>
                        <div  class='info1'>
                             <div className="texto">
                             <label className="label">Público:</label>
                             </div>
                                         <div className="inpus2">
                                         <div>
                                        {'SI  '}
                                         {especimen?.publico ==='si'?<input type='radio' name='publico' value="si" checked='true'  onClick={(e)=> {modificarPublico(e)}} />:<input type='radio' name='publico' value="si"  onClick={(e)=> {modificarPublico(e)}} />}
                                         </div>
                                         <div>
                                         {'NO  '}
                                         {especimen?.publico==='no'?<input type='radio' name='publico' value='no' checked='true' onClick={(e)=> {modificarPublico(e)}}/>:<input type='radio' name='publico' value='no'   onClick={(e)=> {modificarPublico(e)}}/>    }
                                         </div>
                                         </div>



                             </div>
                        <div  class='info1'>
                             <div className="texto">
                             <label className="label">Holotipo:</label>
                             </div>
                                         <div className="inpus2">
                                         <div>
                                        {'SI  '}
                                         {especimen?.holotipo ==='si'?<input type='radio' name='holotipo' value="si" checked='true'  onClick={(e)=> {modificarHolotipo(e)}} />:<input type='radio' name='holotipo' value="si"  onClick={(e)=> {modificarHolotipo(e)}} />}
                                         </div>
                                         <div>
                                         {'NO  '}
                                         {especimen?.holotipo === 'no'?<input type='radio' name='holotipo' value='no' checked='true' onClick={(e)=> {modificarHolotipo(e)}}/>:<input type='radio' name='holotipo' value='no'   onClick={(e)=> {modificarHolotipo(e)}}/>    }
                                         </div>
                                         </div>



                             </div>

                        </div>
                        <div className="col1">
                        <div className="info3B1">

                                    <div className="cabeza5">
                                        <label className="lab">Armario:</label>

                                        <input type='number'  name='armario1'onChange={(e)=> {modificarEspe(e)}} placeholder={modificacion?.espPrev.armario1}/>


                                    </div>
                                    <div className="estante">
                                        <label class='labelo'>Estante </label>
                                        <input className="ubic"  type='number' name='estante1desde'onChange={(e)=> {modificarEspe(e)}} placeholder={modificacion?.espPrev.estante1desde}/>
                                        <label class='labelo'>Hasta</label>
                                        <input className="ubic" type='number'  name='estante1hasta'onChange={(e)=> {modificarEspe(e)}} placeholder={modificacion?.espPrev.estante1hasta}/>

                                    </div>


                    </div>
                    <div className="info3B1">

                               <div className="cabeza5">
                                   <label className="lab">Armario:</label>

                                   <input type='number'  name='armario2'onChange={(e)=> {modificarEspe(e)}} placeholder={modificacion?.espPrev.armario2}/>


                               </div>
                               <div className="estante">
                                   <label class='labelo'>Estante </label>
                                   <input className="ubic"  type='number' name='estante2desde'onChange={(e)=> {modificarEspe(e)}} placeholder={modificacion?.espPrev.estante2desde}/>
                                   <label class='labelo'>Hasta</label>
                                   <input className="ubic" type='number'  name='estante2hasta'onChange={(e)=> {modificarEspe(e)}} placeholder={modificacion?.espPrev.estante2hasta}/>

                               </div>


               </div>



                            <div className="info3">
                                <div className="mostrador3">
                                    <div className="cabeza3">
                                        <label className="lab">Publicaciones</label>
                                        <input onChange={(e) => subirArchivo(e)} type="file" id="pdf" name="pdf" accept="application/pdf" className="buto"/>
                                    </div>
                                        {
                                           
                                        pdfs?.length>0? pdfs?.map(el => {
                                            return <div className="caca2" onClick={(e)=> eliminarArchivo(e, el)}><span tooltip="click para eliminar" className="butono">{el.length>35?el.slice(0,35)+'...pdf':el}</span></div>
                                                }): spinner === 1 ? <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                             </Spinner>
                                                    :
                                                 <>Sin publicaciones cargadas...</>
                                        }
                                </div>
                            </div>
                            <div className="info3">
                                <div className="mostrador3">
                                    <div className="cabeza3">
                                        <label className="lab">Imágenes</label>
                                        <input onChange={(e) => handleImg(e)} type="file" id="imagen" name="imagen" accept="image/png, image/jpeg" className="buto"/>

                                    </div>
                                    {

                                            especimen?.imagen?.length>0?especimen?.imagen.map(el => {
                                                return <div>
                                                    <span tooltip="click para eliminar" className="butono"><img src={el} height="60px" width="80px" alt="image" onClick={(e)=> eliminarImagen(el, e)}></img></span>
                                                   
                                                </div>


                                            }):  <>Sin imágenes </>
                                    }
                                </div>
                            </div>


                    </div>
                    </div>
                    {
            errors?.genero||errors?.especie||errors?.cuenca||errors?.formacion?<div className="pieErr">
                <p className="cargar23" >{'FORMULARIO INCOMPLETO'}</p>
        
          {errors.genero?<p className="cargar2"  onClick={(e)=> crearGeneroEspecie(e)} >{errors.genero}</p>:''}
          {errors.especie?<p className="cargar2" onClick={(e)=> crearEspecie(e)}>{errors.especie}</p>:''}
          {errors.cuenca?<p className="cargar2" onClick={(e)=> crearCuenca(e)}>{errors.cuenca}</p>:''}
          {errors.formacion?<p className="cargar2" onClick={(e)=> crearFormacion(e)} >{errors.formacion}</p>:''}
          
          
</div>:<div className="pie">
                        <p className="cargar" onClick={(e)=>submitEspecimen(e)}>CARGAR</p>
        </div>
        }
                </div>
        </div>



            )
}