import Menu from "./menu";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import './prestamos.css'
import { getDatos, getDatos2, postDatos, subespecimen } from "../store/action";
import React, { useEffect, useState} from "react";
import Swal from 'sweetalert2'
import axios from "axios"
import { useNavigate,Link } from "react-router-dom";

import { BiPrinter,BiTrash } from "react-icons/bi";
import {url} from '../URL.js'
export function Prestamos(){
    const navigate = useNavigate()
    let dispatch = useDispatch()
    let especimenes = useSelector((state)=> state.especimenes)
    let usuario = useSelector((state)=> state.usuario)
    
    let espec = especimenes;
    const { isAuthenticated, user} = useAuth0();
    const [prestamo, setPrestamo] = useState({tipoprestamo:'Préstamo'})
    let fecha = obtenerFecha()
    const [listapres, setListapres] = useState()
    const [prestamoFilter, setPrestamoFilter] = useState()
    const [numb, setNumb] = useState(0)
    const [espeSelect, setEspectSelect] = useState()
    const [numerosEspe, setNumerosEspe] = useState([])
    function traerPrestamos(){
        axios.get(`${url}prestamos`)
        .then(res => setListapres(res.data))
    }
    useEffect(() => {
       dispatch(getDatos2())
       traerPrestamos()
       
         }, [numb])

    function obtenerFecha(){
        let fechaActual
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
            if(month < 10){
            fechaActual = year + '-0' + month + '-' + day /* day + '/0' + month + '/' + year */
            }else{
            fechaActual = year + '-' + month + '-' + day
            }
            if(day < 10){
                fechaActual = year + '-0' + month + '-' + '0' +day
            }
        return fechaActual
    }
    function detallePrestamo (e, elemento){
        let fecha1 = obtenerFecha()
        let put = {especimennumero : elemento.numeroespecimen, prestado: false}
        let update = {id: elemento.id, fechadevolucion:fecha1, devuelto: true }
        let idPrestamo = elemento.id
        //let para = elemento.generoespecie.replace("/","-")
        console.log(elemento)
        if(elemento.devuelto){
            const { value: formValues } =  Swal.fire({
                title: `${elemento.tipoprestamo}` ,
                html: 
                `<p>Prestado por : ${elemento.emisor}</p>` +
                `<p>Investigador: ${elemento.investigador}</p>`+
                `<p>Correo: ${elemento.correo}</p>`+
                `<p>Contacto: ${elemento.contacto}</p>`+
                `<p>Institución: ${elemento.institucion}</p>`+
                `<p>Nro espécimen : ${elemento.numeroespecimen.map( el => {return(subespecimen(el) )})}</p>`+
                `<p>Fecha de préstamo: ${elemento.fechaprestamo}</p>` +
                `<p>Fecha devolución estimada: ${elemento.fechadevolucionest}</p>` +
                `<p>Comentario: ${elemento.comentarios != null ?elemento.comentarios:'Sin comentarios'}` +
                '<br></br>' +
                `<a href='/home/prestamo/plantilla/${elemento.emisor}/${elemento.fechaprestamo}/${elemento.tipoprestamo}/${elemento.investigador}/${elemento.contacto}/${elemento.numeroespecimen}/${elemento.institucion}/${elemento.comentarios}/${elemento.fechadevolucionest}' target='_blank'>generar PDF</a> `,
    
                showCloseButton: true,
                showDenyButton:true,
                showConfirmButton:true,
                denyButtonText:'volver',
               confirmButtonText:'Eliminar',
                preConfirm: () => {   
                   
                  return [
                     axios.delete(`${url}eliminarPrestamos?id=`+ idPrestamo)
                     .then(res => {if(res.status == 200){
                        Swal.fire(
                            'Préstamo Eliminado',
                            'Volver',
                            'success'
                          )
                          setTimeout(() => {traerPrestamos()}, "1000")
                            traerPrestamos()
                     } else {Swal.fire('Algo salio mal, vuelve a intentarlo ', '', 'warning')}})
                     
                      /* setTimeout(() => {traerPrestamos()}, "1000"),
                      traerPrestamos(), */
                    // axios.put(`${url}modificarespre`,put),
                    // setTimeout(() => {traerPrestamos()}, "1000"),
                    // traerPrestamos(),
                   
                  ]  
                }   
              }) 
              if (formValues) {
                Swal.fire(JSON.stringify(formValues)) 
              }

        } else {
            const { value: formValues } =  Swal.fire({
                title: `${elemento.tipoprestamo}` ,
                html: 
                `<p>Prestado por : ${elemento.emisor}</p>` +
                `<p>Investigador: ${elemento.investigador}</p>`+
                `<p>Correo: ${elemento.correo}</p>`+
                `<p>Contacto: ${elemento.contacto}</p>`+
                `<p>Institución: ${elemento.institucion}</p>`+
                `<p>Nro espécimen : ${elemento.numeroespecimen.map( el => {return(subespecimen(el) )})}</p>`+
                `<p>Fecha de préstamo: ${elemento.fechaprestamo}</p>` +
                `<p>Fecha devolución estimada: ${elemento.fechadevolucionest}</p>` +
                `<p>Comentario: ${elemento.comentarios != null? elemento.comentarios:'Sin comentarios'}` +
                '<br></br>' +
                `<a href='/home/prestamo/plantilla/${elemento.emisor}/${elemento.fechaprestamo}/${elemento.tipoprestamo}/${elemento.investigador}/${elemento.contacto}/${elemento.numeroespecimen}/${elemento.institucion}/${elemento.comentarios}/${elemento.fechadevolucionest}' target='_blank'>generar PDF</a> `,
                
                showCloseButton: true,
                showDenyButton:true,
               
                denyButtonText:'volver',
                
                confirmButtonText: 'Devuelto',
                preConfirm: () => {   
                    Swal.fire(
                        'Préstamo Actualizado',
                        'Volver',
                        'success'
                      )
                  return [
                    axios.put(`${url}prestamos`,update),
                    axios.put(`${url}modificarespre`,put),
                    setTimeout(() => {traerPrestamos()}, "1000"),
                    traerPrestamos(),
                   
                  ]  
                }   
              }) 
              if (formValues) {
                Swal.fire(JSON.stringify(formValues)) 
              }
        }

    }

    function changeInput(e){
        setPrestamo({
            ...prestamo,
            [e.target.name] : e.target.value
        })
    }

    function submitPrestamo(e){
        
        let obj = {
             emisor: user?.name,
            fechaprestamo: fecha,
            tipoprestamo: prestamo.tipoprestamo,
            investigador: prestamo.investigador,
            correo: prestamo.correo,
            contacto: prestamo.contacto,
            numeroespecimen: numerosEspe,
            institucion: prestamo.institucion,
            comentarios: prestamo.comentarios,
            fechadevolucionest: prestamo.fechadevolucionest,
            
                }
  
        let investi = document.getElementById('investigador').value
       // let especnumero = document.getElementById('especimennumero').value
        let put = {especimennumero : numerosEspe, prestado: true}
        if(!numerosEspe){
            alert('Selecciona Numero de espécimen')
        }
            
          else 
            {

                    axios.post(`${url}prestamos`,obj)
                    .then(res => {alert('prestamo creado')})
                    axios.put(`${url}modificarespre`, put)
                    traerPrestamos()
                    setPrestamo(null)
                    setNumb(numb +1)

                }

         
    }   let back = 'white'

   
  function filtrarPrestamo(e){
    e.preventDefault()
    if(e.target.value.length === 0){
        setPrestamoFilter(listapres)
    } else{
        //let result = listapres?.filter(el=>el.numeroespecimen.filter( ele => ele==e.target.value))
        if(!e.target.value.includes('-')){
        let result = listapres?.filter(el => el.numeroespecimen.includes(e.target.value+'00'))
        setPrestamoFilter(result)
         }else{
            console.log(e.target.value)
            let result = listapres?.filter(el => el.numeroespecimen.includes(e.target.value.replace('-','')))
            setPrestamoFilter(result)
         }
          
    }

  }
   function prestamoORconsulta(e){
    setPrestamo({
        ...prestamo,
        tipoprestamo : e.target.value
    })
   }
   function filtrarEspecimen(e){

        setNumerosEspe([...numerosEspe, e.target.value])
        
    if(e.target.value.length === 0){
        setEspectSelect()
    }
    else{
        let especimenSelect = espec.filter(el=>el.especimennumero===e.target.value)
        setEspectSelect(especimenSelect)
    }

   }


  let style = {
    color: "green",
    fontStyle:"oblique"
  }
  function eliminarNumero (el, e){
    e.preventDefault()
    let cambio = numerosEspe.filter( occ => occ !== el)
    setNumerosEspe(cambio)
        
    
  }
  let numeroEspecimenes = espec?.map(el => el.especimennumero)
  
  function SortArray(x, y){
    if (Number(x) < Number(y)) {return -1;}
      if (Number(x) > Number(y)) {return 1;}
      return 0;
    }
    
    let numerosSort = numeroEspecimenes?.sort(SortArray12)
    console.log(numeroEspecimenes) 
    function formato(texto){
        return texto.replace(/^(\d{4})-(\d{2})-(\d{2}||\d{1})$/g,'$3-$2-$1');
      }
      function SortArray(x, y){
        if (x.updatedAt > y.updatedAt) {return -1;}
        if (x.updatedAt < y.updatedAt) {return 1;}
        return 0;
    }
    function SortArray12(x, y){
        if (Number(x) < Number(y)) {return -1;}
        if (Number(x) > Number(y)) {return 1;}
        return 0;
    }
    let listPrestamosOrder = listapres?.sort(SortArray)
    let prestamoFilterOrder = prestamoFilter?.sort(SortArray)
    console.log(listPrestamosOrder) 



    function eliminarPrestamo(elemento, e){
        console.log('click')
    }
    return(
        <div class='container34'>
         
            <datalist id='prestamo-numero-especimen'>
                                    {
                                       listapres?.map(eleme => {return <option>{eleme.numeroespecimen}</option>})
                                        //especiefiltrada?.forEach(ele => {return <option>{ele}</option>})
                                       
                                    }
                                   
                                </datalist>
             <datalist id='numeroespecimen'>
                                    {
                                       espec?.map(eleme => {return <option>{eleme.especimennumero}</option>})
                                        //especiefiltrada?.forEach(ele => {return <option>{ele}</option>})
                                       
                                    }
                                   
                                </datalist>
                                <Menu activo={4}/>
            <div className="contenido34">
            <div className="cabecera">
                <div className="apre">
                    LISTA DE PRÉSTAMOS 
               </div>
           </div>
            <div class='cont'>
            <div class='crear-prest'>
                                    <div className="cab-pres">
                                     <h4 className="hhh">Ingresar Préstamo</h4>
                                    </div>
                    
              <form type='submit' onSubmit={(e)=> {submitPrestamo(e)} } class='formulario-prestamo'>            
                        <div class='form-prest'>
                            <div class='conte-div'>
                            <label>Tipo:</label>
                            <select className="sdsd" onChange={(e)=> prestamoORconsulta(e)} required>
                                        <option >seleccionar</option>
                                        <option value='Préstamo' selected>Préstamo</option>
                                        <option value='Consulta'>consulta</option>
                                    </select>
                    </div>
                    <div className="conte-div">
                    <label>Investigador:</label>
                    <input type='text' required name='investigador' id='investigador' className="sdsd" onChange={(e)=> {changeInput(e)}}/>
                    </div>
                    <div className="conte-div">
                    <label>Correo:</label>
                    <input className="sdsd" type='text' name='correo' id='correo' onChange={(e)=> {changeInput(e)}}/>
                    </div>
                    <div className="conte-div">
                    <label>Contacto:</label>
                    <input className="sdsd" type='text' name='contacto' id='contacto' onChange={(e)=> {changeInput(e)}}/>
                    </div>
                    <div className="conte-div">
                    <label>Institución:</label>
                    <input className="sdsd2" type='text' name='institucion' id='institucion' onChange={(e)=> {changeInput(e)}}/>
                    </div>
                    <div className="conte-div2">
                    <label>Nro Espécimen:</label>
                    <select onChange={(e)=> {filtrarEspecimen(e)}} required>
                        <option value=''>Selecione...</option>
                        {
                             numerosSort?.map(eleme => {return <option value={eleme}>{subespecimen(eleme)}</option>})
                        }
                    </select>
                      <div class='gen-selected'>
                    {
                        espeSelect ? <p style={style}>{espeSelect[0]?.genero + " " + espeSelect[0]?.especie}</p> : null
                    }
                    </div> 
                    {/* <input  className="sdsd" type='text' list='numeroespecimen' id='especimennumero'name='numeroespecimen' required onChange={(e)=> {filtrarEspecimen(e)}}/> */}
                    </div>
                       <div class='numeros-de-especimen'>
                        {
                            numerosEspe ? numerosEspe?.map(el => {return <div onClick={(e)=> eliminarNumero(el, e)} class='caca-prestamos'><span tooltip="click para eliminar" >{subespecimen(el)}</span></div>}  ): null
                        }
                        </div>                 




                   
                    <div className="conte-div">
                    <label >Fecha de devolución estimada</label>
                    <input className="sdsd" type='date' required name='fechadevolucionest' id='fechadevolucionest' onChange={(e)=> {changeInput(e)}}/>
                    </div>
                    <p>Comentario</p>
                    <textarea type='text'class='text-pres' placeholder='detalle de entrega/cantidad de fragmentos' name='comentarios'  onChange={(e)=> {changeInput(e)}}/>
                    <button type="submit" class='limpiar'>CREAR PRESTAMO</button>
                </div>
            </form> 
            </div>
            <div class='lista-prest'>
            <div class='buscar-prestamo'>

                <h4 className="hhh">Búsqueda por numero de espécimen:</h4>
                <input  type="text" id='num-prestamo' placeholder="ingrese numero especimen..."onChange={(e)=> {filtrarPrestamo(e)} } class='buscar-prestamo-input' list='prestamo-numero-especimen'></input>
                        
            </div>

            
             <div id="main-container234"> 
             {
                !listapres ? <div class='spiner'><div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              </div> :
            
             <table class="rwd_auto fontsize2">
		<thead className="tabli-headP">
		<tr>
                  <th>Fecha préstamo</th>
                  <th>Tipo</th>
                  <th>Nro Espécimen</th>
                  
                  <th>Investigador</th>
                  <th>Fecha devolución </th>
                  <th>Devuelto</th>
                  
		</tr>
		</thead>
		<tbody>
         
        { 
               
               prestamoFilterOrder?.length>0? prestamoFilterOrder?.map((elemento) => {
                 
                    if(elemento.fechadevolucionest<fecha && elemento.devuelto === false){
                        back='danger'
                      
                    } else{
                        back='pase'
                    }
                    return (
                        
                        <tr key={elemento?.id} onClick={(e)=>detallePrestamo(e,elemento)} class={back} >
                            <td>{formato(elemento?.fechaprestamo)}</td>
                            <td>{elemento.tipoprestamo}</td>
                            <td>{elemento?.numeroespecimen.map(el => {return (subespecimen(el) + " / ")})}</td>
                            
                            <td>{elemento?.investigador}</td>
                            <td>{formato(elemento?.fechadevolucionest)}</td>
                            {
                                elemento?.fechadevolucion ? <td>{formato(elemento?.fechadevolucion)}</td> : <td>Pendiente</td>
                            }
                            
                      </tr>
                        
                    );
                  }) : listPrestamosOrder?.map((elemento) => {
                    if(elemento?.fechadevolucionest<fecha && elemento?.devuelto === false){
                      
                  
                        back='danger'
                      
                    } else{
                      
                        back='pase'
                    }
                    return (
                        
                        <tr key={elemento?.id} onClick={(e)=>detallePrestamo(e,elemento)} class={back} >
                            <td>{formato(elemento?.fechaprestamo)}</td>
                            <td>{elemento.tipoprestamo}</td>
                            <td>{elemento?.numeroespecimen.map(el => {return (subespecimen(el) + " / ")})}</td>
                            
                        <td>{elemento?.investigador}</td>
                        <td>{formato(elemento?.fechadevolucionest)}</td>

                            {
                                elemento?.fechadevolucion ? <td>{formato(elemento?.fechadevolucion)}</td> : <td>Pendiente</td>
                            }
                          
                      </tr>
                    );
                  })
                  
                 
               }
		</tbody>
	</table>
}
             </div>

            </div>
            </div>
            </div>
        </div>
    )
}