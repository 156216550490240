// hay que traer especimen unico desde back
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import './detalle.css'
import './actualizarEspecimen.css'
import { useNavigate } from "react-router-dom";
import { Carousel } from 'react-bootstrap';

import {agregarQR, decimalAGrado, delEsp,subespecimen,filtrarDatos} from '../store/action'
import Menu from "./menu";
import { useDispatch,useSelector} from "react-redux";
import Swal from 'sweetalert2'
import {url} from '../URL.js'
import './detalle.css'



export default function Detalle (){
    const userD = useSelector((state)=> state.usuario)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [especimen, setEspecimen] = useState(null)
    const [pdfState, setPdfState] = useState(null)
    const [prestamoActivo, setPrestamoActivo] = useState({})
    let id = useParams()
    let ids = id.id

    
    useEffect(() => {
        axios.get(`${url}especimen/id?id=${ids}`)
        .then((response) => {
            let num = response.data.especimennumero
      
            setEspecimen(response.data)
            if(response.data.prestado){
             axios.get(`${url}prestamos?id=`+ num)
            .then(res => setPrestamoActivo(res.data))
            }


        })
        return () => {
            setEspecimen(null)
        }
    }, [])
    function goBack(e){
        e.preventDefault()
        navigate(-1)
    }
 
 // parte de lectura y comparacion de pdfs de especimen con carpeta contenedora de pdf
   


  
   

    var coordenadas =  decimalAGrado(especimen?.coordlat,especimen?.coordlong)

    var linkMap='https://www.google.com/maps/place/'+especimen?.coordlat+especimen?.coordlong;
  
  

 
    function agregarqr(e){
        e.preventDefault()
        let idespecimen = especimen?.especimennumero
        dispatch(agregarQR(idespecimen))
        alert('qr generado exitosamente')
    }

    function eliminaEspecimen(e){


        if(window.confirm('Seguro desea liminar el especimen '+subespecimen(e)+' del Catalogo? ')){
            
                var response=delEsp(e);
                dispatch(filtrarDatos({parametro:'limpiar'}))
                alert('Especimen '+subespecimen(e)+' eliminado correctamente!');
                
            return [
                navigate('/home/')
               
              ]  
        }
      
    }

function abririmagen(el){
    Swal.fire({
        imageUrl: `${el}`,
        imageHeight: 300,
        backdrop: `
    rgba(80, 77, 77, 0.70)
    no-repeat
        `
    
      })
}
  
   function abrirPrestamo(){
    const { value: formValues } =  Swal.fire({
        title: `Prestamo` ,
        html: 
        `<p>Prestado por : ${prestamoActivo[0].emisor}</p>` +
        `<p>investigador: ${prestamoActivo[0].investigador}</p>`+
        `<p>institucion: ${prestamoActivo[0].institucion != null?prestamoActivo[0].institucion: 'indefinido'}</p>`+
        `<p>Nro especimen : ${prestamoActivo[0].numeroespecimen ? prestamoActivo[0].numeroespecimen.map(el =>subespecimen(el.toString())).toString(): ''}</p>`+
        `<p>fecha de prestamo: ${prestamoActivo[0].fechaprestamo}</p>` +
        `<p>fecha devolucion estimada: ${prestamoActivo[0].fechadevolucionest}</p>` +
        `<p> comentario: ${prestamoActivo[0].comentarios != null? prestamoActivo[0].comentarios:'Sin comentarios'} </p>`,

        showCloseButton: true,
        showDenyButton:true,
        denyButtonText:'volver',
       confirmButtonText: 'ir a prestamos',
        preConfirm: () => {   
           
          return [
            navigate('/home/prestamos')
           
          ]  
        }   
      }) 
      if (formValues) {
        Swal.fire(JSON.stringify(formValues)) 
      }
   }
console.log(especimen?.partesesqueletales?.length)

    return (

        <div className="container34">    
        <div>
        <Menu activo={0}/>
        </div>
        
        <div className="contenido34">
            <div className="cabecera">
       
                              
                <div className="vuelve">
                <Link to={'/home/'} className="limpiar">VOLVER</Link>
                </div>
              
                    <div className="a">

                    Detalle Espécimen {especimen?subespecimen(especimen?.especimennumero):''}

                    </div>
                {
                     userD.nivel <= 2 ? 
                <div className="vuelve2">
                    <a className="limpiar" onClick={e=>eliminaEspecimen(especimen?.especimennumero)}> ELIMINAR ESPÉCIMEN</a>
                </div>
                        : null
                }
               
            </div>
            <div className="cuerpo">
                <div className="col1-detalle">
                    <div className="info1">
                     
                            <label className="colu1">Género:</label>

                            <h6 class='datosCur2'>{especimen?.genero?especimen.genero:'Sin especificar'}</h6>
                         
                    </div>
                   
                    <div className="info1">   
                    <label className="colu1" >Especie:</label>

                        <h6 class='datosCur2'>{especimen?.especie?especimen.especie:'Sin especificar'}</h6>
                     
                     
                    </div>
                    <div className="info1">
                    <label className="colu1">Nro. Bochón:</label> 
                            <h6 class='datosCur'>{especimen?.bochonnumero!='0'?<a href={`/home/bochon/${especimen?.bochonnumero}`} target='_blank' class='href-detalle'>{especimen?.bochonnumero}</a>:'Sin especificar'}</h6>       
                        
                    </div>
                    <div className="info3-detalle">
                    <div className="mostradorDet">
                                    <div className="cabeza">
                                    <label className="lab">Pos. Filo:</label>
                                    </div>

                                     {
                                     especimen?.posicionfilo?.length===0?<> Sin Posición Filogenética</>:especimen?.posicionfilo?.length<9?especimen?.posicionfilo?.map(el => {return <div className="caca32" >{el}</div>}):
                                     especimen?.posicionfilo?.map(el => {return <div className="caca32B" >{el}</div>})
                                     }
                                     </div>
                      
                    </div>
                    
                    <div className="info3-detalle">
                    <div className="mostradorDet">
                                <div className="cabeza">
                                <label className="lab">Partes:</label>

                                                </div>
                                            {
                                             especimen?.partesesqueletales?.length===0?<><p className="sin"> Sin partes cargadas...</p></>:especimen?.partesesqueletales?.length<9?especimen?.partesesqueletales?.map(el => {return <div className="caca32">{el}</div> }):
                                             especimen?.partesesqueletales?.map(el => {return <div className="caca32B">{el}</div> })
                                            }
                                            
                                        </div>               
                </div>
               
                </div>
                 <div className="col2">
                    <div className="info1">
                        <label >Periodo:</label>
                        <h6 class='datosCur'>{especimen?.periodo}</h6>
                    </div>
                    <div  class='info1'>
                        <label >Época:</label>
                        <h6 class='datosCur'>{especimen?.epoca}</h6>
                        
                    </div>
                    <div  class='info1'>
                        <label >Piso:</label>
                        <h6 class='datosCur'>{especimen?.piso}</h6>
                    </div>
                    <div  class='info1'>
                        <label >Cuenca:</label>
                        <h6 class='datosCur'>{especimen?.cuenca}</h6>
                    </div>
                    <div  class='info1'>
                        <label >Formación:</label>
                        <h6 class='datosCur'>{especimen?.formacion}</h6>
                    </div>
                    
                    <div  class='info1'>
                        <label >Miembro:</label>
                        <h6 class='datosCur'>{especimen?.miembro}</h6>
                    </div>
                    <div  class='info1'>
                        <label >Localidad:</label>
                        <h6 class='datosCur'>{especimen?.localidad}</h6>
                     </div>
                     <div  class='info1'>
                     <label >Campaña:</label>
                     <h6 class='datosCur'>{especimen?.campana}</h6>
                     </div>
                     <div  class='info1'>
                     <label >Fecha Camp.:</label>
                     <h6 class='datosCur'>{especimen?.fechadescubrimiento}</h6>
       
                     </div>
                     {
                    userD.nivel >=3 ? null :
                    <div  class='info1'>
                 <div class='qrs'>
                
                   <div class='qr-div2'onClick={(e)=> agregarqr(e)}>Generar QR</div>
                   <a href={`/home/plantillaqr/${especimen?.especimennumero}`}target='_blank' ><div class='qr-div2'>imprimir QR</div></a>
                 </div>
                 </div>  
                 } 
                 
                                      
               
                   
                </div>
                <div className="col1">
                <div  class='info111'>
                <label >Nro Campo:</label>
                <h6 class='datosCur'>{especimen?.nrocampo}</h6>
        
            
       </div>
       <div className="mostradorDetalle-cord">
                            <div className="cabeza">
                            <label className="lab">Coordenadas Geográficas</label>

                          
                            </div>
                            <div class='info1C'>
                            {coordenadas?.latitud.completa}
                          <br></br>                           {coordenadas?.longitud.completa}
                            </div>
                           
                        
                          
                             </div>
                             <a  className="base1D" href={'https://www.google.com/maps/place/'+especimen?.coordlat+','+especimen?.coordlong} target='_blank'>
                                     <h6 className="h67"> ver en maps! </h6>
                                 </a>
                             <div  class='info111'>
                     <label >Descubridor:</label>
                     <h6 class='datosCur'>{especimen?.descubridor}</h6>
       
                     </div>
   

                
                <div  class='info111'>
                <label >Preparador:</label>
                <h6 class='datosCur'>{especimen?.preparador}</h6>
        
   
                </div>
                <div  class='info111'>
                <label >Fecha Prep.:</label>
                <h6 class='datosCur'>{especimen?.preparacionfecha}</h6>
      
     
                </div >
                <div  class='info111'>
                <label >Fragmentos:</label>
                <h6 class='datosCur'>{especimen?.cantidadfrag}</h6>
           
                </div>
                <div  class='info111'>
                     <label >Holotipo:</label>
                     <h6 class='datosCur'>{especimen?.holotipo.toUpperCase()}</h6>
                    
                  </div>

                <div  class='info1coment'>
                <label >Comentario:</label>
                <textarea type='text' class='textar'name='comentario' defaultValue={especimen?.comentario}/>
       
                </div>
                <div className="info111">
                {
                    especimen?.prestado&& userD.nivel <3  ? 
                               <div class='en-prestamo' onClick={(e)=> abrirPrestamo(e)}>En prestamo</div> : null

                 }
                </div>
               
             
                </div>
                <div className="col4-detalle">
                <div className="info3-detalle1">
                       
                            <div className="cabeza5-detalle1">
                            <h6 class='datos66'>Armario {especimen?.armario1}</h6>
                 
                            </div>
                            <div className="estante-detalle">
                            <h6 class='datos'>{'Estante '+especimen?.estante1desde+' hasta '+especimen?.estante1hasta} </h6>
                    
                            </div>
                                 
                            
            </div>
            <div className="info3-detalle1">
                       
                       <div className="cabeza5-detalle1">
                       <h6 class='datos66'>Armario {especimen?.armario2}</h6>
            
                       </div>
                       <div className="estante-detalle">
                       <h6 class='datos'>Estante {especimen?.estante2desde} hasta {especimen?.estante2hasta} </h6>
               
                       </div>
                            
                       
       </div>
                         
                 
        <div className="info3-publicacion">
                       
                       <div className="cabeza5">
                           <label className="lab">Publicaciones:</label>
                           
            
                       </div>
                       <div className="publicaiones-detalle">
                       {
                            especimen?.pdf?.length>0 ? 
                            especimen?.pdf.map(el => {return <a href={`${url}getpdf/` + el} target="_blank" class='caca326'>{el.length>35?el.slice(0,25)+'...pdf':el}</a>
                            }) : <p>sin pdf</p>
                        }
               
                       </div>
                            
                       
       </div>    
       {
         especimen?.imagen?.length>0? 
         <div className="info3-imagen-detalle">
         <div className="cabeza">
         <label className="lab">Imágenes</label>
         </div>
                     <Carousel >
                         {especimen?.imagen?.map(el => { 
                             return <Carousel.Item>
                            
                             <img 
                                    onClick={e=>abririmagen(el,e)}
                                     src={el}
                                     alt='banner'
                                    className="foto"
                                    />
                         
                             </Carousel.Item>
                         })}
                         </Carousel>

                        </div>
                        :
                        <div className="info3-imagen-detalle2">
                        <div className="cabeza">
                        <label className="lab">Imágenes</label>
                        </div>
                                   <div class='sin-imagen'>SIN IMÁGENES</div>
               
                                       </div>

         }

       
            
            </div>
            </div>
            <div className="pie">
            {

                        userD.nivel >= 3 ? null :
                        <Link  className="pie2" to={`/modificar/${especimen?.especimennumero}`}>
                         MODIFICAR

                        </Link>
                    } 
                    
                            
            </div>
        </div>
</div>

            )
}
