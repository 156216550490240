
import './buscar.css'
import React from "react";
import { useEffect, useState } from 'react';
import dino from './dinoGif.gif';
import logoMuseo from './logomuseogrande.png';
import { filtrarDatos, getDatos,getDatos2,getFilo, getGeneroEspecie, getPartes,subespecimen, fechaActual,getDatos4} from "../store/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Menu from "./menu";
import { jsPDF } from "jspdf";
import { Link, } from "react-router-dom";
import Detalle from './detalle';
import { BiExit,BiSortAlt2 } from 'react-icons/bi';
import { useAuth0 } from "@auth0/auth0-react";
export default function Buscar(){
  const { isAuthenticated, user, isLoading} = useAuth0();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [search, setSearch] = useState({parametro:"", busqueda:""})
    const [orden, setOrden] = useState('menor')
    const userD = useSelector((state)=> state.usuario)
    useEffect (() => {
      dispatch(getGeneroEspecie())
        
         dispatch(getPartes())
            dispatch(getFilo())
            

              if(userD.nivel === 4){
                dispatch(getDatos4());
              } else if(userD.nivel === 3||userD.nivel === 2||userD.nivel === 1){
                dispatch(getDatos2());
              }
           
          
      
      
        },[userD])

    var especimenes = useSelector((state)=> state.especimenes)
    var especimenFiltrado = useSelector((state)=> state.especimenes_filtrados)
    const generoEspecie = useSelector((state)=> state.generoEspecie)
    const [filter, setErrors] = useState({
      genero:false,
      especie:false,
      filo:false,
      partes:false,
      desc:false,
      campa:false,
      numero:false,
    })

    const filogenia = useSelector((state)=> state.filo )
    const partesEsq = useSelector((state)=> state.partes )
  //  console.log('partes:',partesEsq)
       // console.log(especimenes)
   var espec = especimenes;

    
   
     //   console.log('periodoEpoca',periodoEpoca)
   
   
  
if(especimenFiltrado.length===0){
    var genero=[];
    var especie=[];
    var filogen=[];
    var descubridor=[];
    var partes=[];
    var campana=[];

    generoEspecie?.map(e=>{

 
      let generoB=[];
      let especieB=[];
      

      espec?.map(el=>{
        generoB?.push(el.genero)
        especieB?.push(el.especie)
      })

      if(generoB?.includes(e.genero)){
        genero?.push(e.genero)
      }
      e.especie?.map(el=>{
        if(!especie?.includes(el)&&el!=''&&especieB.includes(el))
        especie?.push(el)
      })
    })


    filogenia.map(e=>{
      filogen.push(e.filo)
    })

    partesEsq.map(e=>{
      partes.push(e)
    })


    espec?.map(e=>{
      if(!descubridor.includes(e.descubridor)){
      descubridor.push(e.descubridor)
      }

        if(!campana.includes(e.campana)&&e.campana!=''){
          campana.push(e.campana)
      }
     })
    }else{
      var genero=[];
      var especie=[];
      var filogen=[];
      var descubridor=[];
      var partes=[];
      var campana=[];


      especimenFiltrado.map(e=>{
        if(!genero.includes(e.genero)){
          genero.push(e.genero)
          }
        if(!especie.includes(e.especie)){
            especie.push(e.especie)
           }
      
      e.posicionfilo?.map(el=>{
       if(!filogen.includes(el)){
            filogen.push(el)
          }
        })

        e.partesesqueletales?.map(el=>{
          if(!partes.includes(el)){
               partes.push(el)
             }
           })
   

       if(!descubridor.includes(e.descubridor)){
            descubridor.push(e.descubridor)
          }
    
       if(!campana.includes(e.campana)){
          campana.push(e.campana)
         }
      })
    }

    //  espec.map(e=>{
    //   if(!partes.includes(e.partesesqueletales))
    //   partes.push(e.partesesqueletales)
    //  })



  //  console.log(partes)
  //  console.log(especie)


/*     function onSubmit(e) {
        e.preventDefault();
        if(!search){
            dispatch(getDatos())
        } else {
        dispatch(filtrarDatos(search))
        
        
    }
} */

function selectGenero(e){
    //e.preventDefault()
    //console.log(e.target.value)
    filter.genero=e.target.value!='todos'?e.target.value:null;

    dispatch(filtrarDatos({parametro:'genero',busqueda:e.target.value}))
}
function selectEspecie(e){
  //e.preventDefault()
 // console.log(e.target.value)
 filter.especie=e.target.value!='todos'?e.target.value:null;
 

  dispatch(filtrarDatos({parametro:'especie',busqueda:e.target.value}))
}

function selectFilo(e){
  //e.preventDefault()
  //console.log(e.target.value)
  filter.filo=e.target.value!='todos'?e.target.value:null;

  dispatch(filtrarDatos({parametro:'filo',busqueda:e.target.value}))
}

function selectPartes(e){
  //e.preventDefault()
 //  console.log(e.target.value)
 filter.partes=e.target.value!='todos'?e.target.value:null;

   dispatch(filtrarDatos({parametro:'partes',busqueda:e.target.value}))
  
}

function selectDescubridor(e){
  //e.preventDefault()
  //console.log(e.target.value)
  filter.desc=e.target.value!='todos'?e.target.value:null;

  dispatch(filtrarDatos({parametro:'descubridor',busqueda:e.target.value}))
}

function selectCampana(e){
  //e.preventDefault()
  //console.log(e.target.value)
  filter.campa=e.target.value!='todos'?e.target.value:null;

  dispatch(filtrarDatos({parametro:'campana',busqueda:e.target.value}))
}

function selectNumero(e){
  //e.preventDefault()
 // console.log('slect nomero')

  var id=document.getElementById('num').value+'00';
 // console.log(id)
  dispatch(filtrarDatos({parametro:'numero',busqueda:id}))
 // console.log('filtrosdo',especimenFiltrado)
}
function selectDesde(e){
  //e.preventDefault()
 // console.log('slect nomero')

  var desde=document.getElementById('desde').value+'00';
  var hasta=document.getElementById('hasta').value+'00';
 // console.log(id)
  dispatch(filtrarDatos({parametro:'desde',busqueda:[desde,hasta]}))
 // console.log('filtrosdo',especimenFiltrado)
}

if(especimenFiltrado?.length === 1){
  filter.numero = 1
}


function limpiarFiltros(e){
  //e.preventDefault()
  //console.log('limpiar')
 filter.genero=false; 
 filter.especie=false;
 filter.filo=false;
 filter.partes=false;
 filter.desc=false;
 filter.campa=false;
 filter.numero=false;
 
  
  dispatch(filtrarDatos({parametro:'limpiar'}))
  document.getElementsByClassName('select').value='';
}

function SortArray(x, y){
  if(orden == 'menor'){
    if (Number(x.especimennumero) < Number(y.especimennumero)) {return -1;}
      if (Number(x.especimennumero) > Number(y.especimennumero)) {return 1;}
      return 0;
  } else {
    if (Number(x.especimennumero) > Number(y.especimennumero)) {return -1;}
    if (Number(x.especimennumero) < Number(y.especimennumero)) {return 1;}
    return 0;
  }
}

var especSort = espec?.sort(SortArray) 
var especimenFiltradoSort = especimenFiltrado?.sort(SortArray)
  

    
  function ordenar(){
    if(orden === 'menor'){
      especSort = espec?.sort(SortArray)  
      especimenFiltradoSort = especimenFiltrado?.sort(SortArray)
      setOrden('mayor')
    }else {
      especSort = espec?.sort(SortArray)  
      especimenFiltrado?.sort(SortArray)
      setOrden('menor')
    }
  }
 
  function imprimirPDF(e){
    e.preventDefault()

   var x=50;
    
        const doc = new jsPDF();
        doc.addImage(logoMuseo,10,10,60,25)
        doc.setFontSize(12)
        doc.text("INSTITUTO Y MUESO DE CIENCIAS NATURALES", 65, 12);
        doc.setFontSize(10)
        doc.text("CATÁLOGO DE PALEOFLORA", 83, 17);
        doc.setFontSize(10)
      
        doc.text('Fecha', 169, 18);
        doc.setFont('verdana', 'bold');
        doc.text(fechaActual(), 168, 23);
        doc.rect(70, 23, 90, 10); // empty square
        doc.setFontSize(11)
        doc.setFont('impact', 'bold');
        doc.text('LISTADO DE ESPECIMENES',90,30 )
        doc.text('Especimen',10,40 );
        doc.text('Genero / Especie',35,40 )
        doc.text('Campaña',89,40 )
        doc.text('Nro Campo',165,40 )
        doc.line(10, 42, 185, 42); // horizontal line


        especimenFiltrado?.map(el => {
        
          if(x<280){
            doc.setFont(undefined, 'bold');
            doc.text('PBSJ-'+subespecimen(el.especimennumero),10,x );
            doc.setFont(undefined, 'italic');
            doc.text(el.genero+' '+el.especie,35,x )
            doc.setFont(undefined, 'bold');
            doc.text(el.campana,89,x )
            doc.setFont(undefined, 'regular')
            doc.text(el.nrocampo,165,x )
            doc.text('Partes : '+el.partesesqueletales,10,x+4 )
            doc.line(10,x+6, 185, x+6); 

            
       
            x=x+10;
            
            
           }else{
            x=30;
            doc.addPage()
            doc.text('Especimen',10,20 );
            doc.text('Genero / Especie',35,20 )
            doc.text('Campaña',89,20 )
            doc.text('Nro Campo',165,20 )
            doc.line(10, 22, 185, 22); // horizontal line
            doc.setFont(undefined, 'bold');
            doc.text('PBSJ-'+subespecimen(el.especimennumero),10,x );
            doc.setFont(undefined, 'italic');
            doc.text(el.genero+' '+el.especie,35,x )
            doc.setFont(undefined, 'bold');
            doc.text('Campaña: '+el.campana,89,x )
            doc.setFont(undefined, 'regular')
            doc.text('Nro Campo: '+el.nrocampo,165,x )
            doc.text('Partes : '+el.partesesqueletales,10,x+4 )
            doc.line(10,x+6, 185, x+6); 

            
       
            x=x+10;
           }
          
            
            
        })
       
        doc.setFont(undefined, 'regular');
    
    
        doc.save('Listado de especimenes.pdf');
    
     
    }

  //console.log('posicion',especSort[0].posicionfil)

return (
            <div className="container34" id='tablita'>

              <Menu activo={0}/>
              
               <div class="contenedor">
             
               <div class='nav'>
               <div className="cabecera1">


{especimenFiltradoSort?.length>0 && userD.nivel < 3 ?<h6 onClick={e=>imprimirPDF(e)} class='limpiar'>Generar PDF</h6> :''}

  {especimenFiltrado?.length>0?<h6 onClick={limpiarFiltros} class='limpiar'>LIMPIAR FILTROS</h6>:'' }
 



</div>

                    <div class="selecto">
                      <div class="filters"> 
                       <h3 class='titu'>Género:</h3><p></p>
                       <select class='select' onChange={e => selectGenero(e)}>
                            {filter?.genero?<option value='todos' >seleccione...</option>:<option selected='true' value='todos' >seleccione...</option>}
                            {genero.sort()?.map(e=>{
                              return filter?.genero===e?<option value={e} selected='true'>{e}</option>:<option value={e}>{e}</option>
                            })}             
                        </select>
                      </div>
                      <div class="filters">   
                      <h3 class='titu'>Especie:</h3><p></p>
                       <select class='select' onChange={e => selectEspecie(e)}>
                       {filter?.especie?<option value='todos' >seleccione...</option>:<option selected='true' value='todos' >seleccione...</option>}
                            {especie.sort()?.map(e=>{
                              return filter?.especie===e?<option value={e} selected='true'>{e}</option>:<option value={e}>{e}</option>
                            })}              
                        </select>
                      </div>
                     
                      <div class="filters">
                      <h3 class='titu'>Pos. Filogenética: </h3> <p></p> 
                       <select class='select' onChange={e => selectFilo(e)}>
                            <option value='todos' >seleccione...</option>
                            {filogen.sort()?.map(e=>{
                              return filter?.filo===e?<option value={e} selected='true'>{e}</option>:<option value={e}>{e}</option>
                            })}            
                        </select>
                      </div> 
                      <div class="filters">
                        
                      <h3 class='titu'>Partes:</h3> <p></p>
                        <select class='select' onChange={e => selectPartes(e)}>
                            <option value='todos' >seleccione...</option>
                            {partes.sort()?.map(e=>{
                              return filter?.partes===e?<option value={e} selected='true'>{e}</option>:<option value={e}>{e}</option>
                            })}                
                        </select> 
                      </div>
                     
                      <div class="filters">  
                      <h3 class='titu'>Descubridor:</h3>  <p></p>
                       <select class='select' onChange={e => selectDescubridor(e)}>
                            <option value='todos'>seleccione...</option>
                            {descubridor.sort()?.map(e=>{
                              return filter?.desc===e?<option value={e} selected='true'>{e}</option>:<option value={e}>{e}</option>
                            })}         
                        </select>
                      </div>
                      <div class="filters">  
                      <h3 class='titu'> Campaña:</h3> <p></p>
                       <select class='select' onChange={e => selectCampana(e)}>
                            <option value='todos' selected={filter?.campa?'false':'true'}>seleccione...</option>
                            {campana.sort()?.map(e=>{
                              return filter?.campa===e?<option value={e} selected='true'>{e}</option>:<option value={e}>{e}</option>
                            })}                          
                        </select>
                      </div>
                      <div class="filters">  
                      <h3 class='titu'> Desde - Hasta</h3> 
                      <div class="busq">
                     <div className="desde">
                         <input  type="text" id='desde' placeholder="desde..." class='buscar124'/>
                          <input  type="text" id='hasta' placeholder="hasta..." class='buscar124'/>
                          </div>
                           <h4 onClick={selectDesde} class='boton' >BUSCAR</h4></div>
                     
                      </div>
                      <div class="filters">  
                      <h3 class='titu'> Nro. Espécimen:</h3> 
                      <div class="busq">
                     
                          <input  type="text" id='num' placeholder="ingrese numero..." class='buscar12'/>
                           <h4 onClick={selectNumero} class='boton' id='num' >BUSCAR</h4></div>
                     
                      </div>
                    
                     
                </div>
             
               </div>
              
          <div id="main-container">
            {
              !espec ?
              <div class='spiner'>
              <img src={dino} width='250px'></img>
             
        
          </div>
               :

<div id="content">


          <table class="tabloni">
                  <thead className='tabli-head'>
                  <tr className='ordenar3' >
                      <th  width='9%' onClick={() =>ordenar()} ><p class='ordenar'><span tooltip='ordenar'>Nro Esp <BiSortAlt2/></span></p></th>
                      <th width='10%'><p class='ordenar2'>Género</p></th>
                          <th width='10%'><p class='ordenar2'>Especie</p></th>
                          <th width='21%'><p class='ordenar2'>Pos. Filogenética</p></th>
                          <th width='21%'><p class='ordenar2'>Partes</p></th>  
                          <th width='10%'><p class='ordenar2'>Descubridor</p></th>
                          <th width='10%'><p class='ordenar2'>Campaña</p></th>
                          <th width='9%'><p class='ordenar2'>N°Campo</p></th>
                     
                      </tr>
                 
                  </thead>
                  <div className="tebodi">
                  
                 



                  <tbody className="ordenar4">

                 
                    { 
                 
                  especimenFiltradoSort?.length>0?especimenFiltradoSort.map((elemento) => {
                    {
                      return (

                      userD.nivel == 4 ?
                       
                        <tr key={elemento.especimennumero}  className='ordenar3'>

                          <td width='9%' align='center'><p>{subespecimen(elemento.especimennumero)}</p></td>
                        <td width='10%'align='center'><p class='datosCur3'>{elemento.genero}</p></td>
                        <td width='10%' align='center'><p class='datosCur3'>{elemento.especie}</p></td>
                        <td width='21%' align='center'>{elemento.posicionfilo.length>0 ? elemento.posicionfilo.map(e=>{return e+"; "}): <p>Sin Posición Filogenética</p>}</td>
                        <td width='21%' align='center'>{elemento.partesesqueletales.length>0?elemento.partesesqueletales.map(e=>{return e+"; "}): <p>Sin Partes esqueléticas</p>}</td>
                        <td width='10%' align='center'><p>{elemento.descubridor}</p></td>
                        <td width='10%' align='center'><p>{elemento.campana}</p></td>
                        <td width='9%' align='center'><p>{elemento.nrocampo}</p></td>
                        </tr>
                        : 
                        <tr key={elemento.especimennumero}  onClick={() => navigate(`/home/${elemento.especimennumero}`)}  className='ordenar3'>
                        <td width='9%' align='center'><p>{subespecimen(elemento.especimennumero)}</p></td>
                        <td width='10%'align='center'><p class='datosCur3'>{elemento.genero}</p></td>
                        <td width='10%' align='center'><p class='datosCur3'>{elemento.especie}</p></td>
                        <td width='21%' align='center'>{elemento.posicionfilo.length>0 ? elemento.posicionfilo.map(e=>{return e+"; "}): <p>Sin Posición Filogenética</p>}</td>
                        <td width='21%' align='center'>{elemento.partesesqueletales.length>0?elemento.partesesqueletales.map(e=>{return e+"; "}): <p>Sin Partes esqueléticas</p>}</td>
                        <td width='10%' align='center'><p>{elemento.descubridor}</p></td>
                        <td width='10%' align='center'><p>{elemento.campana}</p></td>
                        <td width='9%' align='center'><p>{elemento.nrocampo}</p></td>
                       
                        
                      </tr>
                      )
                    }
                      
                    }):  


                    especSort?.map((elemento) => {
                    return (

                      userD.nivel == 4 ?
                       
                        <tr key={elemento.especimennumero}  className='ordenar3'>
                        <td width='9%' align='center'><p>{subespecimen(elemento.especimennumero)}</p></td>
                        <td width='10%'align='center'><p class='datosCur3'>{elemento.genero}</p></td>
                        <td width='10%' align='center'><p class='datosCur3'>{elemento.especie}</p></td>
                        <td width='21%' align='center'>{elemento.posicionfilo.length>0 ? elemento.posicionfilo.map(e=>{return e+"; "}): <p>Sin Posición Filogenética</p>}</td>
                        <td width='21%' align='center'>{elemento.partesesqueletales.length>0?elemento.partesesqueletales.map(e=>{return e+"; "}): <p>Sin Partes esqueléticas</p>}</td>
                        <td width='10%' align='center'><p>{elemento.descubridor}</p></td>
                        <td width='10%' align='center'><p>{elemento.campana}</p></td>
                        <td width='9%' align='center'><p>{elemento.nrocampo}</p></td>
                        </tr>
                        : 
                        <tr key={elemento.especimennumero}  onClick={() => navigate(`/home/${elemento.especimennumero}`)}  className='ordenar3'>
                        <td width='9%' align='center'><p>{subespecimen(elemento.especimennumero)}</p></td>
                        <td width='10%'align='center'><p class='datosCur3'>{elemento.genero}</p></td>
                        <td width='10%' align='center'><p class='datosCur3'>{elemento.especie}</p></td>
                        <td width='21%' align='center'>{elemento.posicionfilo.length>0 ? elemento.posicionfilo.map(e=>{return e+"; "}): <p>Sin Posición Filogenética</p>}</td>
                        <td width='21%' align='center'>{elemento.partesesqueletales.length>0?elemento.partesesqueletales.map(e=>{return e+"; "}): <p>Sin Partes esqueléticas</p>}</td>
                        <td width='10%' align='center'><p>{elemento.descubridor}</p></td>
                        <td width='10%' align='center'><p>{elemento.campana}</p></td>
                        <td width='9%' align='center'><p>{elemento.nrocampo}</p></td>
                       

                      </tr>
                    );
                  }) 
                  }
                 
                    </tbody>
                  
 
  
                  </div>
                	      </table>  
                  
                        </div>
            }
<div id="elementH"></div>

             </div>
        </div>
      </div>
    )}
