import react from 'react';
import './menu.css';
import {FiSettings} from "react-icons/fi";

import { useAuth0 } from "@auth0/auth0-react";
import tablinum from "../componentes/ala.png"
import { LogOutButton } from './landingPage/logout';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { BiQrScan,BiLogOut } from "react-icons/bi";

import React, { useEffect, useState} from "react";
import LandingPageTablinum from './landingPage/landingPage';


export default function Menu({activo}) {
    const { isAuthenticated, user,isLoading, logout} = useAuth0();

    const userD = useSelector((state)=> state.usuario)
    const navigate = useNavigate()
    const usuario1 = useSelector((state)=> state.usuario)
    const codigo = useSelector((state)=> state.qrs)
    /* if (isLoading) {
        return <div> Loading...</div>;
      } */


      //console.log('activo',activo)
        const btnContainer = document.getElementById("botones");
      //  console.log('boton container', btnContainer);
        // Get all buttons with class="btn" inside the container
      if(btnContainer) { var btns = btnContainer.getElementsByClassName("botonCito");
 //     console.log('botones',btns)

        
      
   if(activo===5){
    var current = document.getElementsByClassName("active2");
    if (current.length > 0) {
             current[0].className = current[0].className.replace(" active2", "");
           }
   
   }else{
    var current = document.getElementsByClassName("active2");
    if (current.length > 0) {
             current[0].className = current[0].className.replace(" active2", "");
           }

    btns[activo].className=btns[activo].className += " active2";
   
   }
      
  


        // // // Loop through the buttons and add the active class to the current/clicked button
        // for (var j = 0; j < btns.length; j++) {
        //   btns[j].addEventListener("click", function() {
        //     var current = document.getElementsByClassName("active");
        
        //     // If there's no active class
        //     if (current.length > 0) {
        //       current[0].className = current[0].className.replace(" active", "");
        //     }
        
        //     // Add the active class to the current/clicked button
        //     this.className += " active";
        //   });
        // }
    }

    return (
        <div class='padre'>
            { isAuthenticated ? 
                <div>
            <div class='nav-Bar1'>
                    
                <div class='logo-home'>
                    <img src={tablinum} width="100%" height="95%" onClick={() => navigate(`/home`) }/>
                  
                </div>
              
                    {
                        userD.nivel>=3 ?
                <div class='botones' id='botones'>
                     <h4  class="botonCito"  onClick={() => navigate(`/home`) } ><p className='pboton'>Catalogo Especímenes</p></h4>
                </div> :
                <div class="botones" id='botones'>
                
                <h4 class="botonCito" onClick={() => navigate(`/home`) } ><p className='pboton'>Catalogo Especímenes</p></h4>
               
                <h4  class="botonCito"  onClick={() => navigate(`/home/bochon`) } > <p className="pboton">Catalogo de Campo</p> </h4>
              
                <h4  class="botonCito"  onClick={() => navigate(`/home/crear/especimen`) }>  <p className="pboton">Crear Espécimen</p> </h4>

                <h4  class="botonCito"  onClick={() => navigate(`/home/crear/bochon`) } > <p className="pboton">Crear Bochón</p> </h4>
               
                <h4  class="botonCito"  onClick={() => navigate(`/home/prestamos`) } > <p className="pboton">Préstamos</p> </h4>
               
                {
                 codigo[0] ? <a href={`/home/plantillaqr/${codigo}`}target='_blank'  > <BiQrScan color='white' width='80px' /> </a>: null
                }
                </div>

                    }
         
                
                <div class='perfil'>
                  <div>
                    {user?.picture ? <img src={user?.picture} class='img-perfil' width='70%' height='50%'></img> : <></>}
                      </div>
                            <div>
                              <div>
                           <FiSettings size='20px' class='boton-icono'  onClick={() => navigate(`/home/setting/${usuario1.id}`) }/>
                           </div>
                           
                            <div>
                            <BiLogOut size='20px'  class='boton-icono'onClick={() => logout({returnTo: window.location.origin})}/>
                            </div>
                            </div>
                            </div>
                    </div>   
                </div>: navigate('/')
  }
           </div>
    )
}