



/* 
getBochones()

*/

import './buscar.css'
import React from "react";
import { useEffect, useState } from 'react';
import dino from './dinoGif.gif';
import logoMuseo from './logomuseogrande.png';
import { filtrarDatosBochon, getBochones, getFilo, getGeneroEspecie, getPartes, fechaActual } from "../store/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Menu from "./menu";
import { jsPDF } from "jspdf";


export default function BuscarBochon(){
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [search, setSearch] = useState({parametro:"", busqueda:""})
    const [orden, setOrden] = useState('menor')
    var a=0;
    useEffect (() => {
      dispatch(getGeneroEspecie())
        
         dispatch(getPartes())
            dispatch(getFilo())
      dispatch(getBochones());

     
      
        },[])

    var bochones = useSelector((state)=> state.bochones)
    var bochonesFiltrados = useSelector((state)=> state.bochones_filtrados)
    const generoEspecie = useSelector((state)=> state.generoEspecie)
   

    const filogenia = useSelector((state)=> state.filo )
    const partesEsq = useSelector((state)=> state.partes )
    const [filter, setErrors] = useState({
      genero:false,
      especie:false,
      filo:false,
      partes:false,
      desc:false,
      campa:false,
      numero:false,
    })
  //  console.log('partes:',partesEsq)

    let bochons = bochones.data;
    console.log(bochons)
    if(bochons == 'no hay datos en la tabla especimenes del back'){
      bochons = []
    }
      //  console.log('generoEspecie',espec)
     //   console.log('periodoEpoca',periodoEpoca)
    //   console.log('filogenia',filogenia)
   
  
if(bochonesFiltrados?.length===0){
  var genero=[];
    var especie=[];
    var filogen=[];
    var descubridor=[];
    var partes=[];
    var campana=[];

    generoEspecie?.map(e=>{
      
      let generoB=[];
      let especieB=[];
      

      bochons?.map(el=>{
        generoB?.push(el.genero)
        especieB?.push(el.especie)
      })

      if(generoB?.includes(e.genero)){
        genero?.push(e.genero)
      }
      
     // console.log(genero.sort())
      e.especie?.map(el=>{
        if(!especie?.includes(el)&&el!=''&&especieB?.includes(el))
        especie?.push(el)
      })
    })


    filogenia.map(e=>{
      filogen.push(e.filo)
    })

    partesEsq.map(e=>{
      partes.push(e)
    })


    bochons?.map(e=>{
      if(!descubridor.includes(e.descubridor)){
      descubridor.push(e.descubridor)
      }

        if(!campana.includes(e.campana)&&e.campana!=''){
          campana.push(e.campana)
      }
     })
    }else{
      var genero=[];
      var especie=[];
      var filogen=[];
      var descubridor=[];
      var partes=[];
      var campana=[];

      bochonesFiltrados?.map(e=>{
        if(!genero.includes(e.genero)){
          genero.push(e.genero)
          }
        if(!especie.includes(e.especie)){
            especie.push(e.especie)
           }
      
      e.posicionfilo?.map(el=>{
       if(!filogen.includes(el)){
            filogen.push(el)
          }
        })

        e.partesesqueletales?.map(el=>{
          if(!partes.includes(el)){
               partes.push(el)
             }
           })
   

       if(!descubridor.includes(e.descubridor)){
            descubridor.push(e.descubridor)
          }
    
       if(!campana.includes(e.campana)){
          campana.push(e.campana)
         }
      })
    }

    //  espec.map(e=>{
    //   if(!partes.includes(e.partesesqueletales))
    //   partes.push(e.partesesqueletales)
    //  })



  //  console.log(partes)
  //  console.log(especie)


    function onSubmit(e) {
        e.preventDefault();
        if(!search){
            dispatch(getBochones())
        } else {
        dispatch(filtrarDatosBochon(search))
        
        
    }
}

function selectGenero(e){
    //e.preventDefault()
    //console.log(e.target.value)
    filter.genero=e.target.value!='todos'?e.target.value:null;

    dispatch(filtrarDatosBochon({parametro1:'genero',busqueda1:e.target.value}))
}
function selectEspecie(e){
  //e.preventDefault()
 // console.log(e.target.value)
 filter.especie=e.target.value!='todos'?e.target.value:null;
  dispatch(filtrarDatosBochon({parametro1:'especie',busqueda1:e.target.value}))
}

function selectFilo(e){
  //e.preventDefault()
  //console.log(e.target.value)
  filter.filo=e.target.value!='todos'?e.target.value:null;
  dispatch(filtrarDatosBochon({parametro1:'filo',busqueda1:e.target.value}))
}

function selectPartes(e){
  //e.preventDefault()
 //  console.log(e.target.value)
   filter.partes=e.target.value!='todos'?e.target.value:null;
   dispatch(filtrarDatosBochon({parametro1:'partes',busqueda1:e.target.value}))
  
}

function selectDescubridor(e){
  //e.preventDefault()
  //console.log(e.target.value)
  filter.desc=e.target.value!='todos'?e.target.value:null;
  dispatch(filtrarDatosBochon({parametro1:'descubridor',busqueda1:e.target.value}))
}

function selectCampana(e){
  //e.preventDefault()
  //console.log(e.target.value)
  filter.campa=e.target.value!='todos'?e.target.value:null;
 
  dispatch(filtrarDatosBochon({parametro1:'campana',busqueda1:e.target.value}))
}

function selectNumero(e){
 
  var id=document.getElementById('num').value;
 // console.log(id)
  dispatch(filtrarDatosBochon({parametro1:'numero',busqueda1:id}))
}

if(bochonesFiltrados?.length === 1){
 // console.log('entra a bochones filtrados')
  filter.numero = 1
}
function limpiarFiltros(e){
  //e.preventDefault()
 
  filter.genero=false; 
  filter.especie=false;
  filter.filo=false;
  filter.partes=false;
  filter.desc=false;
  filter.campa=false;
  filter.numero=false;
  
  dispatch(filtrarDatosBochon({parametro1:'limpiar'}))
  document.getElementsByClassName('select').value='';
}

 function SortArray(x, y){
  if(orden == 'menor'){
    if (Number(x.bochonnumero) < Number(y.bochonnumero)) {return -1;}
      if (Number(x.bochonnumero) > Number(y.bochonnumero)) {return 1;}
      return 0;
  } else {
    if (Number(x.bochonnumero) > Number(y.bochonnumero)) {return -1;}
    if (Number(x.bochonnumero) < Number(y.bochonnumero)) {return 1;}
    return 0;
  }
}




var bochons1 = bochons?.sort(SortArray) 
var bochonsFilter = bochonesFiltrados?.sort(SortArray)

function ordenar(){
  if(orden === 'menor'){
    bochons1 = bochons?.sort(SortArray)  
    bochonsFilter = bochonesFiltrados?.sort(SortArray)
    setOrden('mayor')
  }else {
    bochons1 = bochons?.sort(SortArray)  
   bochonsFilter = bochonesFiltrados?.sort(SortArray)
    setOrden('menor')
  }
}


function selectDesde(e){
  //e.preventDefault()
 // console.log('slect nomero')

  var desde=document.getElementById('desde').value;
  var hasta=document.getElementById('hasta').value;
 // console.log(id)
  dispatch(filtrarDatosBochon({parametro1:'desde',busqueda1:[desde,hasta]}))
 // console.log('filtrosdo',especimenFiltrado)
}


function imprimirPDF(e){
  e.preventDefault()

 var x=50;
  
      const doc = new jsPDF();
      doc.addImage(logoMuseo,10,10,60,25)
      doc.setFontSize(12)
      doc.text("INSTITUTO Y MUESO DE CIENCIAS NATURALES", 65, 12);
      doc.setFontSize(10)
      doc.text("CATÁLOGO DE PALEOFLORA", 83, 17);
      doc.setFontSize(10)
    
      doc.text('Fecha', 169, 18);
      doc.setFont('verdana', 'bold');
      doc.text(fechaActual(), 168, 23);
      doc.rect(70, 23, 90, 10); // empty square
      doc.setFontSize(11)
      doc.setFont('impact', 'bold');
      doc.text('LISTADO DE BOCHONES',90,30 )
      doc.text('Nro. Bochón',10,40 );
      doc.text('Genero / Especie',35,40 )
      doc.text('Campaña',89,40 )
      doc.text('Nro Campo',165,40 )
      doc.line(10, 42, 185, 42); // horizontal line


      bochonsFilter?.map(el => {
      
        if(x<280){
          doc.setFont(undefined, 'bold');
          doc.text('PBBSJ-'+(el.bochonnumero),10,x );
          doc.setFont(undefined, 'italic');
          doc.text(el.genero+' '+el.especie,35,x )
          doc.setFont(undefined, 'bold');
          doc.text(el.campana,89,x )
          doc.setFont(undefined, 'regular')
          doc.text(el.nrocampo,165,x )
          doc.text('Partes : '+el.partesesqueletales,10,x+4 )
          doc.line(10,x+6, 185, x+6); 


          
     
          x=x+10;
          
          
         }else{
          x=30;
          doc.addPage()
          doc.text('Nro Campo',10,20 );
          doc.text('Genero / Especie',35,20 )
          doc.text('Campaña',89,20 )
          doc.text('Nro Campo',165,20 )
          doc.line(10, 22, 185, 22); // horizontal line
          doc.setFont(undefined, 'bold');
          doc.text('PBBSJ-'+(el.bochonnumero),10,x );
          doc.setFont(undefined, 'italic');
          doc.text(el.genero+' '+el.especie,35,x )
          doc.setFont(undefined, 'bold');
          doc.text(el.campana,89,x )
          doc.setFont(undefined, 'regular')
          doc.text(el.nrocampo,165,x )
          doc.text('Partes : '+el.partesesqueletales,10,x+4 )
          doc.line(10,x+6, 185, x+6); 


          
     
          x=x+10;
         }
        
          
          
      })
      
      doc.setFont(undefined, 'regular');
  
  
      doc.save('Listado de especimenes.pdf');
  
   
  }

return (
           <div className="container34">
              <Menu activo={1}/>
           
               <div class="contenedor">
               <div class='nav'>
               <div className="cabecera1">


{bochonsFilter?.length>0?<h6 onClick={e=>imprimirPDF(e)} class='limpiar'>Generar PDF</h6> :''}

  {bochonesFiltrados?.length>0?<h6 onClick={limpiarFiltros} class='limpiar'>LIMPIAR FILTROS</h6>:'' }


          
          


</div>

                     
<div class="selecto">
                      <div class="filters"> 
                       <h3 class='titu'>Género:</h3><p></p>
                       <select class='select' onChange={e => selectGenero(e)}>
                            {filter?.genero?<option value='todos' >seleccione...</option>:<option selected='true' value='todos' >Todos...</option>}
                            {genero.sort()?.map(e=>{
                              return filter?.genero===e?<option value={e} selected='true'>{e}</option>:<option value={e}>{e}</option>
                            })}             
                        </select>
                      </div>
                      <div class="filters">   
                      <h3 class='titu'>Especie:</h3><p></p>
                       <select class='select' onChange={e => selectEspecie(e)}>
                       {filter?.especie?<option value='todos' >seleccione...</option>:<option selected='true' value='todos' >seleccione...</option>}
                            {especie.sort()?.map(e=>{
                              return filter?.especie===e?<option value={e} selected='true'>{e}</option>:<option value={e}>{e}</option>
                            })}              
                        </select>
                      </div>
                     
                      <div class="filters">
                      <h3 class='titu'>Pos. Filogenética: </h3> <p></p> 
                       <select class='select' onChange={e => selectFilo(e)}>
                            <option value='todos' >seleccione...</option>
                            {filogen.sort()?.map(e=>{
                              return filter?.filo===e?<option value={e} selected='true'>{e}</option>:<option value={e}>{e}</option>
                            })}            
                        </select>
                      </div> 
                      <div class="filters">
                        
                      <h3 class='titu'>Partes:</h3> <p></p>
                        <select class='select' onChange={e => selectPartes(e)}>
                            <option value='todos' >seleccione...</option>
                            {partes.sort()?.map(e=>{
                              return filter?.partes===e?<option value={e} selected='true'>{e}</option>:<option value={e}>{e}</option>
                            })}                
                        </select> 
                      </div>
                     
                      <div class="filters">  
                      <h3 class='titu'>Descubridor:</h3>  <p></p>
                       <select class='select' onChange={e => selectDescubridor(e)}>
                            <option value='todos'>seleccione...</option>
                            {descubridor.sort()?.map(e=>{
                              return filter?.desc===e?<option value={e} selected='true'>{e}</option>:<option value={e}>{e}</option>
                            })}         
                        </select>
                      </div>
                      <div class="filters">  
                      <h3 class='titu'> Campaña:</h3> <p></p>
                       <select class='select' onChange={e => selectCampana(e)}>
                            <option value='todos' selected={filter?.campa?'false':'true'}>seleccione...</option>
                            {campana.sort()?.map(e=>{
                              return filter?.campa===e?<option value={e} selected='true'>{e}</option>:<option value={e}>{e}</option>
                            })}                          
                        </select>
                      </div>
                      <div class="filters">  
                      <h3 class='titu'> Desde - Hasta</h3> 
                      <div class="busq">
                     <div className="desde">
                         <input  type="text" id='desde' placeholder="desde..." class='buscar124'/>
                          <input  type="text" id='hasta' placeholder="hasta..." class='buscar124'/>
                          </div>
                           <h4 onClick={selectDesde} class='boton' >BUSCAR</h4></div>
                     
                      </div>
                      <div class="filters">  
                      <h3 class='titu'> Nro. Bochón:</h3> 
                      <div class="busq">
                     
                          <input  type="text" id='num' placeholder="ingrese numero..." class='buscar12'/>
                           <h4 onClick={selectNumero} class='boton' id='num' >BUSCAR</h4></div>
                     
                      </div>
                    
                     
                </div>
             
               </div>
              
          <div id="main-container">
             {
              !bochons ? <div class='spiner'>
              <img src={dino} width='250px'></img>
             
        
          </div>
             :

          <table class="rwd_auto fontsize">
                        <thead className='tabli-head'>
                        <tr className='ordenar3'>
                        <th width='9%' onClick={() =>ordenar()} ><p class='ordenar'>Nro</p></th>
                        <th width='10%'><p class='ordenar2'>Género</p></th>
                        <th width='10%'><p class='ordenar2'>Especie</p></th>
                        <th width='21%'><p class='ordenar2'>Pos. Filogenética</p></th>
                        <th width='21%'><p class='ordenar2'>Partes</p></th>  
                        <th width='10%'><p class='ordenar2'>Descubridor</p></th>
                        <th width='10%'><p class='ordenar2'>Campaña</p></th>
                        <th width='9%'><p class='ordenar2'>N°Campo</p></th>
                                
                        </tr>
                        </thead>
                        <div className="tebodi">
                        <tbody>
                        { 
                      
                bochonsFilter?.length>0?bochonesFiltrados.map((elemento) => {
             
                    return (
                        <tr key={elemento.bochonnumero} onClick={() => {a=1; navigate(`/home/bochon/${elemento.bochonnumero}`)}}>
                     <td width='9%' align='center'><p>{elemento.bochonnumero}</p></td>
                          <td width='10%' align='center'><p class='datosCur3'>{elemento.genero}</p></td>
                          <td width='10%' align='center'><p class='datosCur3'>{elemento.especie}</p></td>
                          <td width='21%' align='center'>{elemento.posicionfilo.length>0 ? elemento.posicionfilo.map(e=>{return e+"; "}): <p>Sin Posición Filogenética</p>}</td>
                          <td width='21%' align='center'>{elemento.partesesqueletales.length>0?elemento.partesesqueletales.map(e=>{return e+"; "}): <p>Sin Partes esqueléticas</p>}</td>
                          <td width='10%' align='center'>{elemento.descubridor}</td>
                          <td width='10%' align='center'>{elemento.campana}</td>
                          <td width='9%' align='center'>{elemento.nrocampo}</td>
                        
                      </tr>
                    );
                  }):
                  bochons1?.map((elemento) => {
                  return (
                    <tr key={elemento.bochonnumero} onClick={() => {a=1; navigate(`/home/bochon/${elemento.bochonnumero}`)}}>
                           <td width='9%' align='center'><p>{elemento.bochonnumero}</p></td>
                          <td width='10%' align='center'><p class='datosCur3'>{elemento.genero}</p></td>
                          <td width='10%' align='center'><p class='datosCur3'>{elemento.especie}</p></td>
                          <td width='21%' align='center'><p class='ordenar2'>{elemento.posicionfilo.length>0 ? elemento.posicionfilo.map(e=>{return e+"; "}): <p>Sin Posición Filogenética</p>}</p></td>
                          <td width='21%' align='center'><p class='ordenar2'>{elemento.partesesqueletales.length>0?elemento.partesesqueletales.map(e=>{return e+"; "}): <p>Sin Partes esqueléticas</p>}</p></td>
                          <td width='10%' align='center'><p class='ordenar2'>{elemento.descubridor}</p></td>
                          <td width='10%' align='center'><p class='ordenar2'>{elemento.campana}</p></td>
                          <td width='9%' align='center'><p class='ordenar2'>{elemento.nrocampo}</p></td>
                        
                    </tr>
                  );
                })}
              
                        </tbody>
                        </div>
                      </table>
             }
 
          </div>
        </div>
      </div>
    )}