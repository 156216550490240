


const initialState = {
    especimenes: [],
    especimenes_filtrados: [],
    generoEspecie :[],
    periodoepoca:[],
    especie:[],
    cuencaformacion:[], 
    formacion:[], 
    epoca:[],
    filo:[],
    partes:[],
    usuario:[],
    qrs:[],
    bochones:[],
    bochones_filtrados:[],
    especimenesHome:[],

}

export default function reducer( state = initialState, action ) {
    switch(action.type) {
      case 'USUARIO':
      
        return{
          ...state,
          usuario: action.payload
        }
        case 'USUARIO_UP':
          return{
            ...state,
            usuario: action.payload
          }
          case 'GET_BOCHONES':
            return{
              ...state,
              bochones: action.payload
            }
        case 'GET_NOMBRES':
            return {
               ...state,
               especimenes: action.payload     
            }
            case 'GET_NOMBRES2':
              return {
                 ...state,
                 especimenes: action.payload.data[0]     
              }
        case 'AGREGAR_QR':
         let codigo = state.qrs
         codigo.push(action.payload)
            return {
              ...state,
              qrs: codigo
            }
        case 'FILTRAR_DATOS':
       
      
      const {parametro,busqueda}=action.payload;
      var result;
      if(parametro==='limpiar'){
        result=[];
      }else if(parametro==='numero'){
        var especimenesNew=state.especimenes;
        result= especimenesNew.filter(e=>e.especimennumero.slice(0,e.especimennumero.length-2)===busqueda.slice(0,busqueda.length-2));
      
        if(result.length===0){
         alert('especimen no encontrado')
        }
      }
        else if(parametro==='desde'){
          var especimenesNew=state.especimenes;
          console.log('entra desde',busqueda,especimenesNew)
          result= especimenesNew.filter(e=>Number(e.especimennumero)>=Number(busqueda[0])&&Number(e.especimennumero)<=Number(busqueda[1]));
        
          if(result.length===0){
           alert('especimen no encontrado')
          }
      }
      else{

       if(state.especimenes_filtrados.length===0)   {
        var especimenesNew=state.especimenes;
       }else{
        var especimenesNew=state.especimenes_filtrados;
       }

        if(parametro==='genero'){
            result= especimenesNew.filter(e=>e.genero===busqueda);
          // console.log(result)
        }
        if(parametro==='especie'){
             result= especimenesNew.filter(e=>e.especie===busqueda);
           // console.log(result)
         }
         if(parametro==='filo'){
            result= especimenesNew.filter(e=>e.posicionfilo.includes(busqueda));
           // console.log(result)
         }
         if(parametro==='partes'){
         
          result= especimenesNew.filter(e=>e.partesesqueletales.includes(busqueda));
           // console.log(result)
         }
         if(parametro==='descubridor'){
             result= especimenesNew.filter(e=>e.descubridor===busqueda);
           // console.log(result)
         }
         if(parametro==='campana'){
             result= especimenesNew.filter(e=>e.campana===busqueda);
           // console.log(result)
         }
       // const filtrados=state.especimenes.filter(e=>{return e.genero===action.payload.genero});
       // console.log(state.especimenes);
       
       }
    
        return {
            ...state,
            especimenes_filtrados:result,
           
        }

        case 'CLEAN':

        return{
            ...state,
            especimenes_filtrados: []
        }
      

    // ---------------FILTRO BOCHONES -----------------//
    case 'FILTRAR_DATOS_BOCHON':
      //  console.log(action.payload)
      
      const {parametro1,busqueda1}=action.payload;
      //console.log(parametro1)
      var result;
      if(parametro1==='limpiar'){
        result=[];
      }else if(parametro1==='numero'){
        var especimenesNew=state.bochones.data;
        result= especimenesNew.filter(e=>e.bochonnumero===busqueda1);
        
        if(result.length===0){
          alert('Bochón no encontrado')
         }
      } else if(parametro1==='desde'){
        var especimenesNew=state.bochones.data;
        console.log('entra desde',busqueda1,especimenesNew)
        result= especimenesNew.filter(e=>Number(e.bochonnumero)>=Number(busqueda1[0])&&Number(e.bochonnumero)<=Number(busqueda1[1]));
      
        if(result.length===0){
         alert('especimen no encontrado')
        }
    }
      else{

       if(state.bochones_filtrados?.length===0)   {
        var especimenesNew=state.bochones.data;
       }else{
        var especimenesNew=state.bochones_filtrados;
       }

        if(parametro1==='genero'){
            result= especimenesNew.filter(e=>e.genero===busqueda1);
         
        }
        if(parametro1==='especie'){
             result= especimenesNew.filter(e=>e.especie===busqueda1);
          
         }
         if(parametro1==='filo'){
            result= especimenesNew.filter(e=>e.posicionfilo.includes(busqueda1));
           
         }
         if(parametro1==='partes'){
         
          result= especimenesNew.filter(e=>e.partesesqueletales.includes(busqueda1));
         
         }
         if(parametro1==='descubridor'){
             result= especimenesNew.filter(e=>e.descubridor===busqueda1);
          
         }
         if(parametro1==='campana'){
             result= especimenesNew.filter(e=>e.campana===busqueda1);
          
         }
      
       
       }
    
        return {
            ...state,
            bochones_filtrados:result,
           
        }

        case 'CLEAN':

        return{
            ...state,
            bochones_filtrados: []
        }
      


        // ---------- GET DE TABLAS --------------------------//
        case 'GET_GENEROESPECIE':
         //   console.log(action.payload)
                return {
                    ...state,
                    generoEspecie: action.payload.data
                }

        case 'GET_CUENCAFORMACION':

                return {
                    ...state,
                    cuencaformacion: action.payload.data
                }

        case 'GET_PERIODOEPOCA':

                return {
                    ...state,
                    periodoepoca: action.payload.data
                }
        case 'GET_FILO':
            return {
                ...state,
                filo : action.payload.data
            }
            case 'GET_PARTES':
          //    console.log('reducer',action.payload)
              return {
                  ...state,
                  partes : action.payload
              }

        //-----------------------------------------------------------------//
        

        // ------------- FILTRADO DE TABLAS--------------------------------//
        case 'SELECT_ESPECIE':
            //console.log(action.payload)
            let filtrado = state.generoEspecie.filter((el) => el.genero === action.payload)
         //   console.log(filtrado[0].especie)
                return{
                    ...state,
                    especie: filtrado[0].especie
                }


        case 'SELECT_CUENCA':
            //console.log(action.payload)
            let filtradoFormacion = state.cuencaformacion.filter((el) => el.cuenca === action.payload)
         

                return{
                        ...state,
                        formacion   : filtradoFormacion[0].formacion
                }
        
        case 'SELECT_EPOCA':
              //      console.log(action.payload)
                    let filtradoEpoca = state.periodoepoca.filter((el) => el.periodo === action.payload)
                 
        
                return{
                        ...state,
                        epoca   : filtradoEpoca[0].epoca
                }
      /*    case 'SELECT_PISO':
            let filtradoPiso = state.periodoepoca.       
                */ 
        
        default:
            
            return state
}
}