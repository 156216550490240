
import './detalleMobil.css'
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Carousel } from 'react-bootstrap';
import {url} from '../URL.js'

import tablinum from "../componentes/ala.png"

import {subespecimen} from '../store/action/index.js'


export function DetalleMobil(){
    let id = useParams()
    let ids = id.id
    const [especimen, setEspecimen] = useState(null)
     useEffect(() => {
        axios.get(`${url}especimen/id?id=${ids}`)
        .then((response) => {
            setEspecimen(response.data) 
        })
        return () => {
            setEspecimen(null)
        }
    }, []) 

    return ( <div class='padre432'>
    <div class='hijo3'> 
       <img src={tablinum} alt="logo" height='35px'/>
       <h1 className='espe'>Especimen {especimen?.especimennumero?subespecimen(especimen?.especimennumero):''}</h1>
    </div>
    <div class='hijo1'> 
        {/* <label class='italicNO'>Especimen Nro:</label>
        <p>{ especimen?.especimennumero ? subespecimen(especimen?.especimennumero.toString()): ''}</p> */}
    </div>
    <div class='hijo1'> 
        <div className="hijo11">
        <label class='italicNO'>Genero</label>
        </div>
        <div className="hijo12">
            <p class='italic'>{especimen?.genero}</p>
        </div>
        
    </div>
    <div class='hijo1'> 
    <div className="hijo11">
          <label class='italicNO'>Especie</label>
          </div>
        <div className="hijo12">
        <p class='italic'>{especimen?.especie}</p>
        </div>
         
    </div>
 
    <div class='hijo1'> 
    <div className="hijo11">
        <label class='italicNO'>Posicion Filogenetica</label>
      
        {especimen?.posicionfilo.length>0?especimen?.posicionfilo.map(e=>{
            return <p class='caca'>{e}</p>
        }): <p >Sin posicion</p>}
       
    </div>
    </div>
    <div class='hijo1'> 
    <div className="hijo12">
        <label class='italicNO'>Partes Esqueletales</label>
     
        {especimen?.partesesqueletales.map(e=>{
            return <p class='caca'>{e}</p>
        })}
      
    </div>
    </div>
    <div class='hijo1'> 
        <div className="hijo11">
        <label class='italicNO'>Periodo</label>
        </div>
        <div className="hijo12">
        <p>{especimen?.periodo}</p>
        </div>
       
        
    </div>
    <div class='hijo1'>
        <div className="hijo11">
        <label class='italicNO'>Epoca</label>
        </div>
        <div className="hijo12">
        <p>{especimen?.epoca}</p>
        </div>
        
       
    </div>
    <div class='hijo1'> 
    <div className="hijo11">
    <label class='italicNO'>Piso</label>
    </div>
    <div className="hijo12">
    <p>{especimen?.piso}</p>
    </div>
      
      
    </div>
 
    <div class='hijo1'> 
    <div className="hijo11">
    <label class='italicNO'>Cuenca</label>
    </div>
    <div className="hijo12">
    <p>{especimen?.cuenca}</p>
    </div>
        
        
    </div>
   
    <div class='hijo1'> 
    <div className="hijo11">
    <label class='italicNO'>Formacion</label>
    </div>
    <div className="hijo12">
    <p>{especimen?.formacion}</p>
    </div>
      
      
    </div>
    <div class='hijo1'>
   
    <div className="hijo11">
    <label class='italicNO'>Miembro</label>
    </div>
    <div className="hijo12">
    <p>{especimen?.miembro}</p>
    </div> 
      
   
    </div>

    <div class='hijo1'> 
    <div className="hijo11">
    <label class='italicNO'>Localidad</label>
    </div>
    <div className="hijo12">
    <p>{especimen?.localidad}</p>
    </div>
        
    </div>

    <div class='hijo1'> 
    <div className="hijo11">
    <label class='italicNO'>Coordenadas</label>
    </div>
    <div className="hijo13">
    <p>{especimen?.coordlat}</p>
    <p>{especimen?.coordlong}</p>
    <a className="" href={'https://www.google.com/maps/place/'+especimen?.coordlat+','+especimen?.coordlong} target='_blank'>ver en maps</a>
    </div>
 
     
       
    </div>
    {
    especimen?.camapana?
    <div class='hijo1'> 
        <div className="hijo11">
        <label class='italicNO'>Campaña</label>
        </div>
        <div className="hijo12">
        <p>{especimen?.camapana}</p>
    </div>
    </div>:''
}
    <div class='hijo1'> 
    <div className="hijo11">
    <label class='italicNO'>Descubridor</label>
    </div>
    <div className="hijo12">
    <p>{especimen?.descubridor}</p>
        </div>
    </div>
    <div class='hijo1'> 
    <div className="hijo11">
    <label class='italicNO'>Fecha Descubrimiento</label>
</div>
<div className="hijo12">
<p>{especimen?.fechadescubrimiento}</p>
</div>
        
        
    </div>
    <div class='hijo1'> 
    <div className="hijo11">
    <label class='italicNO'>Número de Campo</label>
</div>
<div className="hijo12">
<p>{especimen?.nrocampo}</p>
</div>
        
        
    </div>
    <div class='hijo1'> 
    <div className="hijo11">
    <label class='italicNO'>Preparador</label>
</div>
<div className="hijo12">
<p>{especimen?.preparador}</p>
</div>
       
      
    </div>
    <div class='hijo1'> 
    <div className="hijo11">
    <label class='italicNO'>Fecha Preparación</label>
</div>
<div className="hijo12">
<p>{especimen?.preparacionfecha}</p>
</div>
       
       
    </div>
   
    <div class='hijo1'> 
    <div className="hijo11">
    <label class='italicNO'>Cantidad de Fragmentos</label>
</div>
<div className="hijo12">
<p>{especimen?.cantidadfrag}</p>
</div>
        
  
    </div>
    <div class='hijo1'> 
    <div className="hijo11">
    <label class='italicNO'>Comentarios</label>
</div>
<div className="hijo12">
<p>{especimen?.comentario}</p>
</div>
       
      
    </div>
    <div class='hijo1'> 
    <div className="hijo11">

</div>

 
    </div>
    
</div>
    )

}