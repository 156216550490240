
import './App.css';
import Detalle from './componentes/detalle.jsx';
import { Routes ,Route } from 'react-router-dom';
import Menu from './componentes/menu.jsx';
import dino from './dinoGif.gif';
import Buscar from './componentes/buscar';
import ActualizarEspecimen from './componentes/actualizarEspecimen';
import CrearEspecimen from './componentes/crearEspecimen';
import LandingPageTablinum from './componentes/landingPage/landingPage';
import Prueba from './componentes/prueba';
import { Setting } from './componentes/setting';
import { Prestamos } from './componentes/prestamos';
import { Plantillaqr } from './componentes/plantillaqr';
import { DetalleMobil } from './componentes/detalleMobil';
import { useAuth0 } from "@auth0/auth0-react";
import { crearUsuario, usuarioup } from './store/action';
import { useDispatch } from "react-redux";
import BuscarBochon from './componentes/buscarbochon';
import DetalleBochon from './componentes/detalleBochon';
import ActualizarBochon from './componentes/modificarBochon';
import { PlanillaPrestamo } from './componentes/planillaPrestamo';




function App() {
  const { isAuthenticated, user, isLoading} = useAuth0();
  const dispatch = useDispatch()
  
  if (isLoading) {
    return <div class='spiner'>
      <img src={dino} width='250px'></img>
     

  </div>
  }
  if(isAuthenticated){
    dispatch(crearUsuario({
      id: user.sub,
      correo: user.email,
      imagen: user.picture,
      nombre : user.name
    
    }))
  }
  return (
  
      <div class="App">
        {
          isAuthenticated ?
        <Routes>
        <Route exact path="/" element={<LandingPageTablinum/>}/>
        <Route exact path='/menu' element={<Menu/>} />
        <Route exact path='/home/:id' element={<Detalle/>} />
        <Route exact path='/home/bochon/:id' element={<DetalleBochon/>} />
        <Route exact path='/home' element={<Buscar/>} />
        <Route exact path='/home/bochon' element={<BuscarBochon/>} />
        <Route exact path='/modificar/:id' element={<ActualizarEspecimen/>} />
        <Route exact path='/modificar/bochon/:id' element={<ActualizarBochon/>} />
        <Route exact path='/home/crear/:que' element={<CrearEspecimen/>} />
        <Route exact path='/prueba' element={<Prueba/>} />
        <Route exact path='/home/setting/:id' element={<Setting/>} />
        <Route exact path='/home/prestamos' element={<Prestamos/>} />
        <Route exact path='/home/plantillaqr/:qr' element={<Plantillaqr/>} />
        <Route exact path='/home/prestamo/plantilla/:emisor/:fecha/:tipo/:receptor/:contacto/:nro/:insti/:comen/:fechade' element={<PlanillaPrestamo/>} />
        <Route   path='/home/qr/:UUID/:id' element={<DetalleMobil/>} />

        

        </Routes>:
        <Routes>
          <Route exact path='/' element={<LandingPageTablinum/>}/>
          <Route   path='/home/qr/:UUID/:id' element={<DetalleMobil/>} />
        </Routes>
       
      }
      </div>
    
  );
}

export default App;
