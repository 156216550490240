import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Carousel } from 'react-bootstrap';
//import './actualizarEspecimen.css';
import {agregarQR, decimalAGrado, delBochon, postEspecimen, putBochon, subespecimen} from '../store/action'
import Menu from "./menu";
import { useDispatch,useSelector} from "react-redux";
import {url} from '../URL.js'
import Swal from "sweetalert2";

const reqPdfs = require.context ( '../pdf', true, /.pdf$/ )


export default function DetalleBochon (){
    const userD = useSelector((state)=> state.usuario)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [especimen, setEspecimen] = useState(null)
    const [numeros, setNumeros] = useState()

   
    let id = useParams()
    let ids = id.id

    
    useEffect(() => {
        axios.get(`${url}bochon/especimen/id?id=${ids}`)
        .then((response) => {
            setEspecimen(response.data)
        axios.get(`${url}especimen/?parametro=nuevo`)   
        .then((respons) => {setNumeros(respons.data)}) 

        })
        return () => {
            setEspecimen(null)
        }
    }, [])
    function goBack(e){
        e.preventDefault()
        navigate(-1)
    }
    
 // parte de lectura y comparacion de pdfs de especimen con carpeta contenedora de pdf
    let pdfs = reqPdfs?.keys()


    let pdfArr = [];
    //console.log('especimen modif', especimen?.modificado)
    //console.log('latitud : ',especimen?.coordlat)
    //console.log('long : ',especimen?.coordlong)

    var coordenadas =  decimalAGrado(especimen?.coordlat,especimen?.coordlong)

    var linkMap='https://www.google.com/maps/place/'+especimen?.coordlat+especimen?.coordlong;
  
  

     if(pdfs.length>0){
    for(let i = 0; i < pdfs?.length; i++){
        especimen?.pdf?.map(el => {
            if('./' + el == pdfs[i]){
                pdfArr.push(i)
            }
        }
        )
    }
    } 
    function agregarqr(e){
        e.preventDefault()
        let idespecimen = especimen?.especimennumero
        dispatch(agregarQR(idespecimen))
    }

    function eliminaEspecimen(e){


        if(window.confirm('Seguro desea liminar el bochon '+e+' del Catalogo? ')){
            
                var response=delBochon(e);
                console.log(response);
                alert('Bochon '+e+' eliminado correctamente!');

            return [
                navigate('/home/bochon')
               
              ]  
        }
      
    }
    function bochonAespeciemen (){
        let cord = { latitud: especimen.coordlat,
            longitud: especimen.coordlong}
      let obj =  numeros.faltantes.reduce(function(target, key, index) {
            target[key] = subespecimen(key.toString());
           
            return target;
          }, {})
        //  console.log(obj)
          
          let obj1 = [numeros.newId]
          let obj1Key = obj1.reduce(function(target, key, index) {
            target[key] =subespecimen(key.toString());
            return target;
          }, {})
          
        const { value: fruit } =  Swal.fire({
            title: 'selecciona Nro de especimen',
            input: 'select',
            inputOptions: {
                'Ultimo numero' :obj1Key,
                'Numeros disponibles': obj,
            }, 
       
        
            inputPlaceholder: '',
            showCancelButton: true,
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value) {
                Swal.fire('Creacion exitosa', '', 'success')
                    
                  //dispatch(postEspecimen([especimen, cord, value]))
                  axios.post(`${url}especimen`, [especimen, cord, value])
                  .then((response) => {
                    setTimeout(() => {window.location.reload()}, "1000")
                    })
                    
                 
                 
                } else {
                  resolve('tienes que seleccionar uno :)')
                }
              })
            }
          })

    } 
    function abririmagen(el){
        Swal.fire({
            imageUrl: `${el}`,
            imageHeight: 300,
            backdrop: `
        rgba(80, 77, 77, 0.70)
        no-repeat
            `
        
          })
    }
   

    return (
        <div className="container34">    
        <div>
        <Menu activo={1}/>
        </div>
        
        <div className="contenido34">
            <div className="cabecera">
            <div className="vuelve">
                <Link to={'/home/bochon/'} className="limpiar">VOLVER</Link>
                </div>
              
                    <div className="a">
                    Detalle bochon {especimen?.bochonnumero}
                    </div>
                
                <div className="vuelve2">
                    <a className="limpiar" onClick={e=>eliminaEspecimen(especimen?.bochonnumero)}> ELIMINAR BOCHÓN</a>
                 
                </div>
            </div>
            <div className="cuerpo">
                <div className="col1-detalle">
                    <div className="info1">
                     
                            <label className="colu1">Género:</label>

                            <h6 class='datosCur2'>{especimen?.genero?especimen.genero:'Sin especificar'}</h6>
                         
                    </div>
                   
                    <div className="info1">   
                    <label className="colu1" >Especie:</label>

                        <h6 class='datosCur2'>{especimen?.especie?especimen.especie:'Sin especificar'}</h6>
                     
                        
                     
                    </div>
                    <div className="info1">
                      
                      <label className="colu1">Nro. Espécimen:</label>
                      <h6 class='datosCur'>{especimen?.especimennumero =='0'  || ' ' || '' ? 'Sin especificar' : <a href={`/home/${especimen?.especimennumero}`} target='_blank' class='href-detalle'>{especimen?.especimennumero/100}</a>}</h6>  
                     
                   </div>
               
                    <div className="info3-detalle">
                       


                         <div className="mostradorDet">
                                    <div className="cabeza">
                                    <label className="lab">Pos. Filo:</label>
                                    </div>

                                     {
                                       especimen?.posicionfilo?.length===0?<> Sin Posición Filogenética</>:especimen?.posicionfilo?.length<9?especimen?.posicionfilo?.map(el => {return <div className="caca32" >{el}</div>}):
                                       especimen?.posicionfilo?.map(el => {return <div className="caca32B" >{el}</div>})
                                     }
                                     </div>
                      
                    </div>
                    
                    <div className="info3-detalle">
                                     
                                
                    <div className="mostradorDet">
                                <div className="cabeza">
                                <label className="lab">Partes:</label>

                                                </div>
                                            {
                                            especimen?.partesesqueletales?.length===0?<><p className="sin"> Sin partes cargadas...</p></>:especimen?.partesesqueletales?.length<9?especimen?.partesesqueletales?.map(el => {return <div className="caca32">{el}</div> }):
                                            especimen?.partesesqueletales?.map(el => {return <div className="caca32B">{el}</div> })
                                            }
                                            
                                        </div>               
                </div>
                </div>
                 <div className="col2">
                    <div className="info1">
                        <label >Periodo:</label>
                        <h6 class='datosCur'>{especimen?.periodo}</h6>
                    </div>
                    <div  class='info1'>
                        <label >Época:</label>
                        <h6 class='datosCur'>{especimen?.epoca}</h6>
                        
                    </div>
                    <div  class='info1'>
                        <label >Piso:</label>
                        <h6 class='datosCur'>{especimen?.piso}</h6>
                    </div>
                    <div  class='info1'>
                        <label >Cuenca:</label>
                        <h6 class='datosCur'>{especimen?.cuenca}</h6>
                    </div>
                    <div  class='info1'>
                        <label >Formación:</label>
                        <h6 class='datosCur'>{especimen?.formacion}</h6>
                    </div>
                    
                    <div  class='info1'>
                        <label >Miembro:</label>
                        <h6 class='datosCur'>{especimen?.miembro}</h6>
                    </div>
                    <div  class='info1'>
                        <label >Localidad:</label>
                        <h6 class='datosCur'>{especimen?.localidad}</h6>
                     </div>
                     <div  class='info1'>
                     <label >Campaña:</label>
                     <h6 class='datosCur'>{especimen?.campana}</h6>
                     </div>
                     <div  class='info1'>
                     <label >Fecha Camp.:</label>
                     <h6 class='datosCur'>{especimen?.fechadescubrimiento}</h6>
       
                     </div>
                    
                     <div  class='info1'>
                                      
               
                     </div>  
                </div>
                <div className="col1">
                <div  class='info1'>
                <label >Nro Campo:</label>
                <h6 class='datosCur'>{especimen?.nrocampo}</h6>
        
            
       </div>
       <div className="mostradorDetalle-cord">
                            <div className="cabeza">
                            <label className="lab">Coordenadas Geográficas</label>

                          
                            </div>
                            <div class='info1C'>
                            {coordenadas?.latitud.completa}
                          <br></br>                           {coordenadas?.longitud.completa}
                            </div>
                           </div>
                           <a  className="base1D" href={'https://www.google.com/maps/place/'+especimen?.coordlat+','+especimen?.coordlong} target='_blank'>
                                     <h6 className="h67"> ver en maps! </h6>
                                 </a>   
   
                                 <div  class='info111'>
                     <label >Descubridor:</label>
                     <h6 class='datosCur'>{especimen?.descubridor}</h6>
       
                     </div>
   
                  
                <div  class='info1'>
                <label >Preparador:</label>
                <h6 class='datosCur'>{especimen?.preparador}</h6>
        
   
                </div>
                <div  class='info1'>
                <label >Fecha Prep.:</label>
                <h6 class='datosCur'>{especimen?.preparacionfecha}</h6>
      
     
                </div >
                <div  class='info1'>
                <label >Fragmentos:</label>
                <h6 class='datosCur'>{especimen?.cantidadfrag}</h6>
           
                </div>
                <div  class='info1'>
                     <label >Holotipo:</label>
                     <h6 class='datosCur'>{especimen?.holotipo.toUpperCase()}</h6>
                    
                  </div>

                  <div  class='info1coment'>
                <label >Comentario:</label>
                <textarea type='text' class='textar'name='comentario' defaultValue={especimen?.comentario}/>
       
                </div>
               
             
                </div>
                <div className="col4-detalle">
                <div className="info3-detalle1">
                       
                            <div className="cabeza5-detalle1">
                              
                                <h6 class='datos66'>Armario {especimen?.armario1}</h6>
                 
                            </div>
                            <div className="estante-detalle">
                            <h6 class='datos'>{'Estante '+especimen?.estante1desde+' hasta '+especimen?.estante1hasta} </h6>
                    
                            </div>
                                 
                            
            </div>
            <div className="info3-detalle1">
                       
                       <div className="cabeza5-detalle1">
                       <h6 class='datos66'>Armario {especimen?.armario2}</h6>
            
                       </div>
                       <div className="estante-detalle">
                       <h6 class='datos'>Estante {especimen?.estante2desde} hasta {especimen?.estante2hasta} </h6>
               
                       </div>
                            
                       
       </div>
                         
                 
        <div className="info3-publicacion">
                       
                       <div className="cabeza5">
                           <label className="lab">Publicaciones:</label>
                           
            
                       </div>
                       <div className="publicaiones-detalle">
                       {
                            especimen?.pdf?.length>0 ? 
                            especimen?.pdf.map(el => {return <a href={`${url}getpdf/` + el} target="_blank" class='caca326'>{el.length>35?el.slice(0,25)+'...pdf':el}</a>
                            }) : <p>sin pdf</p>
                        }
               
                       </div>
                            
                       
       </div>    
       {
         especimen?.imagen?.length>0? 
         <div className="info3-imagen-detalle">
         <div className="cabeza">
         <label className="lab">Imágenes</label>
         </div>
                     <Carousel variant= 'dark'>
                         {especimen?.imagen?.map(el => { 
                             return <Carousel.Item>
                             <div class='div-imagen'>
                             <img 
                                    onClick={e=>abririmagen(el,e)}
                                     src={el}
                                     alt='banner'
                                     class='carrusel-item-css'
                             />
                             </div>
                             </Carousel.Item>
                         })}
                         </Carousel>

                        </div>
                        :
                        <div className="info3-imagen-detalle2">
                        <div className="cabeza">
                        <label className="lab">Imágenes</label>
                        </div>
                                   <div class='sin-imagen'>SIN IMÁGENES</div>
               
                                       </div>

         }
            
            </div>
            </div>
            <div className="pie">
            {

                        userD.nivel >= 3 ? null :
                        <>
                        <Link  className="pie2" to={`/modificar/bochon/${especimen?.bochonnumero}`}>
                         MODIFICAR
                        </Link>
                        {
                            especimen?.especimennumero == '0' ?
                            <a  className="pie2" onClick={(e)=> bochonAespeciemen(e)}>GENERAR ESPÉCIMEN</a>
                            : null
                        }
                       </>
            } 
                            
            </div>
        </div>
</div>
            )
}