import React, { useEffect } from "react";
import { LogOutButton } from "./logout";
import { LoginButton } from "./login";
import { Perfil } from "./perfil";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import './landingPage.css'
import { Link } from "react-router-dom";
import { crearUsuario } from "../../store/action";
import tablinum from "../favicon.png";
import {url} from '../../URL.js'
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export default function LandingPageTablinum() {
  const navigate = useNavigate()
  const { isAuthenticated, user} = useAuth0();
  const usuario1 = useSelector((state)=> state.usuario)
  const dispatch = useDispatch()
 
  const ruta = `/home`

  useEffect (() => {
       if(isAuthenticated){
      dispatch(crearUsuario({
        id: user.sub,
        correo: user.email,
        imagen: user.picture,
        nombre : user.name
      
      }))
    }
    
  },[isAuthenticated])
  let styleDiv = {
    marginTop: "25px"
  }
  let styleBotones = {
    marginTop: "25px",
    padding: "10px",
    display: 'flex',
    justifyContent: 'space-around',
  }
  console.log(usuario1)
  function setPassWord(e){
 e.preventDefault();
 console.log(typeof e.target[0].value)
 //confirm('Desea guardar este PIN?')
  if(window.confirm('Desea guardar este PIN?')){
    var pass = Number(e.target[0].value)
    var obj = {id:usuario1.id, contrasena: pass}
    axios.put(`${url}modificarUsuario`, obj).then(navigate(`/home`))
 } 

  }
  function ingresar(e){
    var pass = document.getElementById('pass').value
  console.log(pass)
  if(pass.length>0 && pass == usuario1.contrasena){
      pass = ''
      navigate(`/home`)
    } else if(pass.length === 4 && pass != usuario1.contrasena){
      document.getElementById('resultado').innerHTML = 'PIN incorrecto';
    } else if(pass.length === 0){
      document.getElementById('resultado').innerHTML = '';
    }
    

  }
//console.log(user)
  return (
    <div>    
    <div class="containers">
      <div class='marco'> 
        <div class='boton-login-div' >
      
     
      {isAuthenticated? (
        <>        
        <div>
        <img src={tablinum} width="30%" ></img>
           <h2>TABLINUM</h2>
           </div>
          <Perfil />
          {
            usuario1?.contrasena==null?
            <form type='submit' onSubmit={(e)=> {setPassWord(e)} }>
             <div><label class='label-pin'>Debe crear PIN (4 digitos numericos)</label><input type='password'class='input-pin' autoComplete='false' name='password' maxlength='4' pattern="\d*"required></input>
             <button class='buton-pin' type='submit'>crear</button></div> 
             </form>
            :<div>
            <label>PIN:</label>
            <input class='input-pin' type='password' name='password' id='pass' maxlength='4' pattern="\d*"required onChange={(e)=> ingresar(e)}></input>
            <p id='resultado' class='resultado1'></p>
            </div>

           // <Link to={ruta}><button class="btn btn-primary btn-sm w-40">Ingresar</button></Link>
          }
          <div style={styleBotones}>
          {/* <Link to={ruta}><button class="btn btn-primary btn-sm w-40">Ingresar</button></Link> */}
          
          <LogOutButton />
          </div>
        </>
      ) 
        :
      (
        <div>
          <img src={tablinum} width="30%"></img>
            <div class='text'>
            <h1>TABLINUM</h1>
            <LoginButton />
            </div>
       {/*  <img src={logo} alt="Logo" width="40%" class="img-fluid"/>
        <img src={bienvenido} alt="Logo" width="70%" class="img-fluid"/> */}
        <br/>
        <br/>
        <br/>
        </div>
      )}
        </div>
      </div>
    </div>
   {/*  <footer class={global.footer}>
    <img src={logowhite} height="55px" alt="logo"/>
    </footer> */}
    </div>
  );
}